import Button from './Button';
import './style/ConfirmModal.css';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateUserToChange } from '../../redux/keystoneSlice';
import { updateRole, removeMember , setShowModal } from '../../redux/keystoneInterfaceSlice';
import { useSelector } from 'react-redux';
import Label from './Label';

const ConfirmModal = ({ confirmText, confirmTextDisabled, contextText, contextTextDisabled, disabled, primaryButtonText, primaryButtonDisabledText, secondaryButtonText }) => {
    const keystoneUserToChange = useSelector((state) => state?.keystone?.userToChange);
    const keystoneUserToDelete = useSelector((state) => state?.keystone?.userToDelete);
    const keystoneInterfaceAccessToken = useSelector((state) => state?.keystoneInterface?.accessToken);
    const keystoneInterfaceModalType = useSelector((state) => state?.keystoneInterface?.modalType);

    const dispatch = useDispatch();

    const handleConfirmation = (event) => {
        event.preventDefault();
        if (disabled) {
            dispatch(setShowModal(false));
            dispatch(updateUserToChange(null));
        } else {
            if (keystoneInterfaceModalType === "delete-user") {
                dispatch(removeMember({
                    accessToken: keystoneInterfaceAccessToken,
                    user_id: keystoneUserToDelete?.id
                }));
                dispatch(setShowModal(false));
            }

            if (keystoneInterfaceModalType === "change-user-role") {
                dispatch(updateRole({
                    accessToken: keystoneInterfaceAccessToken,
                    user_id: keystoneUserToChange?.id,
                    role: keystoneUserToChange?.role === "VIEWER" ? "ADMIN" : "VIEWER"
                }));

                dispatch(setShowModal(false));
            }
        }
    };

    return (
        <div className="keystone-confirm-modal modal-wrapper">
            <div className="modal-header pad-s pad-h-l">
                <Label content={!disabled ? confirmText : confirmTextDisabled} size="m" bold="true" displayFont={true} />
            </div>
            <div className="pad-l no-pad-bot">
                {!disabled ? (
                    <Label content={contextText} size="m" />
                ) : (
                    <Label content={contextTextDisabled} size="m" />
                )}
            </div>
            <div className="modal-footer flex-1 justify-end pad-l h-gap-l">
                <div className="product-vertical-container">
                    <div className="product-horizontal-container h-gap-s">
                        {!disabled && <Button label={secondaryButtonText} large="true" onClick={() => dispatch(setShowModal(false))} />}
                        <Button brand={true} label={!disabled ? primaryButtonText : primaryButtonDisabledText} large="true" onClick={handleConfirmation} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ConfirmModal);