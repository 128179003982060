import './style/Placeholder.css';
import React from 'react';
import { } from '../../redux/keystoneSlice';
import { } from '../../redux/keystoneInventorySlice';
import Label from './Label';

const Placeholder = ({ label }) => {

    return (
        <div className="keystone-placeholder product-horizontal-container flex-1 align-center justify-center">
            <Label content={label} size="l" bold={true} secondary={true} displayFont={true} />
        </div>
    );
};

export default React.memo(Placeholder);