import './style/Plaid.css';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEvent as useEffectEvent } from '@reactuses/core';
import { } from '../../redux/keystoneSlice';
import { } from '../../redux/keystoneInventorySlice';
import {
    usePlaidLink
} from 'react-plaid-link';
import { fetchPlaidLinkToken, addPlaidAccounts, fetchCompany, setAddBankAccount } from '../../redux/keystoneInterfaceSlice';

const Plaid = () => {
    const keystoneInterfacePlaidLinkToken = useSelector((state) => state?.keystoneInterface?.plaidLinkToken);
    const keystoneInterfaceAccessToken = useSelector((state) => state?.keystoneInterface?.accessToken);
    const keystoneInterfacePlaidAccountsLinked = useSelector((state) => state?.keystoneInterface?.plaidAccountsLinked);
    const keystoneInterfaceAddBankAccount = useSelector((state) => state?.keystoneInterface?.addBankAccount);
    
    const dispatch = useDispatch();

    const config = {
        onSuccess: (publicToken, metadata) => {
            dispatch(addPlaidAccounts({ accessToken: keystoneInterfaceAccessToken, publicToken }));
            console.log('metadata: ' + metadata);
        },
        onExit: (err, metadata) => {
            dispatch(setAddBankAccount(false));
            console.log('onExit: ' + err);
            console.log('metadata: ' + metadata);
        },
        onEvent: (eventName, metadata) => {
            console.log('onEvent: ' + eventName);
            console.log('metadata: ' + metadata);
        },
        
        token: keystoneInterfacePlaidLinkToken,
    };
    const { open, ready, error } = usePlaidLink(config);

    useEffect(() => {
        if (keystoneInterfaceAccessToken) {
            dispatch(fetchPlaidLinkToken({ accessToken: keystoneInterfaceAccessToken }));
        }
    }, [dispatch, keystoneInterfaceAccessToken]);

    useEffect(() => {
        if (keystoneInterfacePlaidAccountsLinked) {
            dispatch(setAddBankAccount(false));
            fetchCompanyData();
        }
    }, [dispatch, keystoneInterfacePlaidAccountsLinked]);

    const fetchCompanyData = useEffectEvent(() => {
        dispatch(fetchCompany({ accessToken: keystoneInterfaceAccessToken }));
    });

    useEffect(() => {
        console.log('ready: ' + ready);
    }, [ready]);

    useEffect(() => {
        console.log('error: ' + error);
    }, [error]);

    useEffect(() => {
        if (keystoneInterfaceAddBankAccount && ready) {
            openPlaid();
        }
    }, [keystoneInterfaceAddBankAccount, ready]);

    const openPlaid = useEffectEvent(() => {
        open();
    });

    return (
        <div className="keystone-plaid">

        </div>
    );
};

export default React.memo(Plaid);