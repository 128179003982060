import './style/PayoutDetails.css';
import React from 'react';
import Button from './Button';
import { formatToCurrency } from '../../util/Calculate';
import LinkedCard from './LinkedCard';
import Divider from './Divider';
import CheckCircle from '../../media/check-circle@3x.png';
import InfoCircle from '../../media/info-circle@3x.png';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAddBankAccount } from '../../redux/keystoneInterfaceSlice';

import TrayHeader from './TrayHeader';

const PayoutDetails = () => {
    const dispatch = useDispatch();

    const keystoneTrayData = useSelector((state) => state?.keystone?.trayData);
    const keystoneInventoryBankAccounts = useSelector((state) => state?.keystoneInventory?.bank_accounts);

    return (
        <div className="keystone-offer-details product-vertical-container flex-1 width-100">
            <TrayHeader
                type="Payout"
                label={`#${keystoneTrayData?.id}`}
            />
            <div className="product-vertical-container flex-1 width-100 pad-xl justify-center">
                <LinkedCard 
                    label={new Date().toLocaleDateString()}
                    details="Buyer Paid For Goods"
                    first={true}
                    icon={CheckCircle}
                    color="green"
                />
                <Divider 
                    vertical={true}
                    height={64}
                />
                <LinkedCard 
                    details="Goods Received by Buyer"
                    label="1ZAC98210307481260"
                    icon={CheckCircle}
                    color="green"
                />
                <Divider
                    vertical={true}
                    height={64}
                />
                <LinkedCard 
                    details={keystoneInventoryBankAccounts?.length === 0 ? "No Bank Information Added" : `Funds Released (*${keystoneInventoryBankAccounts?.[0]?.account_mask})`}
                    label={formatToCurrency(keystoneTrayData?.amount * 0.84)}
                    last={true}
                    icon={keystoneInventoryBankAccounts?.length === 0 ? InfoCircle : CheckCircle}
                    color={keystoneInventoryBankAccounts?.length === 0 ? "orange" : "green"}
                    content={keystoneInventoryBankAccounts?.length === 0  ? (
                        <div className="product-horizontal-container justify-end pad-top-8">
                            <Button 
                                label="Connect Account"
                                onClick={() => dispatch(setAddBankAccount(true))}
                                brand={true}
                                flex={true}
                            />
                        </div>
                    ) : null }
                />
            </div>
        </div>
    );
};

export default React.memo(PayoutDetails);