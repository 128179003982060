import { combineReducers } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import keystoneReducer from './keystoneSlice';
import keystoneInventoryReducer from './keystoneInventorySlice';
import keystoneInterfaceReducer from './keystoneInterfaceSlice';

// Create a reset action
export const resetAppState = createAction('resetAppState');

// Combine reducers
const appReducer = combineReducers({
  keystone: keystoneReducer,
  keystoneInventory: keystoneInventoryReducer,
  keystoneInterface: keystoneInterfaceReducer,
});

const rootReducer = (state, action) => {
  if (action.type === resetAppState.type) {
    // Clear the session storage
    sessionStorage.clear();
    // Reset the state by passing undefined
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;