import './style/FileManager.css';
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { } from '../../redux/keystoneSlice';
import Label from './Label';
import HeaderVisual from './HeaderVisual';
import Button from './Button';

const FileManager = ({ handleFileSelection, file, setFile }) => {
    const [fileName, setFileName] = useState(null);
    const keystoneProductData = useSelector((state) => state?.keystone?.productData);

    const [dropHover, setDropHover] = useState(false);
    const [fileInputKey, setFileInputKey] = useState(0);

    const displayDropZone = !file;

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            setFile(event.target.files[0]);
            setFileName(event.target.files[0].name);

            handleFileSelection({
                file: event.target.files[0],
                fileName: event.target.files[0].name
            });

            // Clear the input.
            setFileInputKey(fileInputKey + 1);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const file = event.dataTransfer.files[0];
        if (file) {
            console.log(file);
            handleFileChange({ target: { files: [file] } });
        }
        setDropHover(false);

    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDropHover(true);
    };

    const handleDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.dataTransfer.dropEffect = 'copy';
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDropHover(false);
    };

    return (
        <div className={`keystone-file-manager ${displayDropZone && 'cta-drop-wrapper'} product-vertical-container v-gap-l ${keystoneProductData && "cta"}`}>
            <div 
                className={`${displayDropZone ? 'product-vertical-container drop-zone pad-xl' : 'product-horizontal-container justify-center align-center'} v-gap-s h-gap-s ${dropHover ? "hover" : ""}`}
                onDrop={displayDropZone ? handleDrop : null} 
                onDragOver={displayDropZone ? handleDragOver : null}
                onDragLeave={displayDropZone ? handleDragLeave : null}
                onDragEnter={displayDropZone ? handleDragEnter : null}>
                {file &&
                    <div id="uploaded-file-wrapper">
                        <Label size='s' bold={true} displayFont={true} content={fileName} />
                    </div>
                }
                <p className={`center-text size-m bold line-m mobile-hide`}>
                    {!file && "Drag and drop or "}
                    <label htmlFor="file-upload" className="file-label">
                        {!file && "select to upload"}
                        <input 
                            type="file"
                            id="file-upload" 
                            className="file-input" 
                            accept=".csv, .xlsx, .tsv" 
                            onChange={handleFileChange}
                            key={fileInputKey} />
                    </label>
                    {file && <Button customId={"replace-file-button"} label={"Replace File"} secondary={true} onClick={() => document.getElementById("file-upload").click()} /> }
                    {!file && " a .csv, .tsv, or .xlsx file of your excess inventory with the following columns:"}
                </p>
                <div className="product-vertical-container desktop-hide">
                    <Label content="Select a .csv, .tsv, or .xlsx file of your excess inventory with the following columns:" displayFont={true} bold={true} center={true} />
                </div>
                {!file && (
                    <div className="product-vertical-container v-gap-l">
                        <div className="product-horizontal-container h-gap-s v-gap-xs flex-wrap justify-center">
                            <HeaderVisual label="Unique ID" tooltip="Identifier that you use internally that does not change." />
                            <HeaderVisual label="MPN" tooltip="Manufacturer's part number." />
                            <HeaderVisual label="Mfg" tooltip="Original equipment manufacturer." />
                            <HeaderVisual label="Qty" tooltip="Amount on hand at location." />
                            <HeaderVisual label="Warehouse Name" tooltip="Unique identifier for the warehouse the item is stored in." />
                            <HeaderVisual label="Unit Value" tooltip="Current item value on books. Required for reclamation goals." />
                        </div>
                        <div className="product-vertical-container v-gap-s">
                            <Label content="The following columns are optional but enhance your experience." bold={true} secondary={true} onBrand={true} displayFont={true} size="s" line="s" center={true} />
                            <div className="product-horizontal-container h-gap-s v-gap-xs flex-wrap justify-center">
                                <HeaderVisual label="Bin" tooltip="Name of physical location inside warehouse." />
                                <HeaderVisual label="Condition" tooltip="Condition the specific items are in. If left out it assumed all items are new and in original box" />
                                <HeaderVisual label="Qty Type" tooltip="How the quantity is measured (units, weight, feet, etc.)" />
                                <HeaderVisual label="Description" tooltip="Additional details about the item." />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="product-vertical-container desktop-hide">
                <label id="file-upload-input-wrapper" htmlFor="file-upload" className="file-label">
                    <Label content={file ? "Replace File" : "Upload File"} displayFont={true} />
                    <input 
                        type="file"
                        id="file-upload" 
                        className="file-input" 
                        accept=".csv, .xlsx, .tsv" 
                        onChange={handleFileChange}
                        key={fileInputKey} />
                </label>
            </div>
        </div>
    );
};

export default React.memo(FileManager);