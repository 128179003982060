import './style/Login.css';
import React, { useState } from 'react';
import google from '../media/google.png';
import Label from '../component/keystone/Label';
import Divider from '../component/keystone/Divider';
import { setAuthRedirectPage } from '../redux/keystoneSlice';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

const Login = () => {
    const [userEmail, setUserEmail] = useState(null);
    const { loginWithRedirect, error } = useAuth0();
    const dispatch = useDispatch();
        
    const handleLoginWithEmail = async () => {
        dispatch(setAuthRedirectPage('inventory'));
        return await loginWithRedirect({
            authorizationParams: {
                connection: 'email',
                login_hint: userEmail
            }
        });
    };

    const handleLoginWithGoogle = async () => {
        dispatch(setAuthRedirectPage('inventory')); // go to inventory after login
        return await loginWithRedirect({
            authorizationParams: {
                connection: 'google-oauth2'
            }
        });
    };
    
    return (
        <div id="login">
            <div id="login-wrapper">
                <div id="login-content-wrapper">
                    <div id="login-header-wrapper" className="product-vertical-container v-gap-xs">
                        <Label content="Login" size="xl" bold={true} color="brand" />
                        <Label content="Inventory analytics and exploration hub." size="m" bold={true} secondary={true} invert={true} />
                    </div>
                    <div id="login-details-wrapper" className="product-vertical-container v-gap-l">
                        <div className="product-vertical-container flex-1 width-100 v-gap-m">
                            <input id="create-account-email-input" className="sf-mono size-m" type="email" placeholder="Email Address" value={userEmail} onChange={(e) => setUserEmail(e.target.value)}/>
                            <button className="create-account-button" onClick={handleLoginWithEmail}>
                                <Label content="Login" size="m" bold={true} displayFont={true} />
                            </button>
                        </div>
                        <Divider label="or" />
                        <button id="login-google-button" onClick={handleLoginWithGoogle}>
                            <img src={google} alt="Google's 'G' Logo"/>
                            <Label content="Sign in with Google" size="m" bold={true} displayFont={true} />
                            <img src={google} alt="Google's 'G' Logo"/>
                        </button>
                        <Label content="Access your dashboard using your organization email address." size="s" bold={true} secondary={true} displayFont={true} />
                    </div>
                    <div id="login-footer-wrapper" className={error ? "cta" : ""}>
                        <p id="login-redirect">Not a customer? Check out <a href="https://trymaterialize.com">what we do here</a>.</p>
                    </div>
                </div>
                <a href='/privacy' id="login-privacy-anchor" className="size-s secondary">Privacy Policy</a>
            </div>
        </div>
    );
};

export default Login;