import Label from './Label';
import './style/Loader.css';
import React, { useState, useEffect } from 'react';

const Loader = ({ loading, showLoadingText, loadingTextOptions }) => {
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    useEffect(() => {
        if (showLoadingText) {
            const interval = setInterval(() => {
                setCurrentTextIndex((currentTextIndex + 1) % loadingTextOptions.length);
            }, 4000);
            return () => clearInterval(interval);
        }
    }, [currentTextIndex, showLoadingText, loadingTextOptions]);

    return (
        <div id="keystone-loader" className={loading ? 'shown' : 'hidden'}>
            <div className="keystone-loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            {showLoadingText && <div id="loader-label">
                <Label content={loadingTextOptions[currentTextIndex]} size="l" bold={true} displayFont={true} />   
            </div>}
            <div id="lazy-loader">
                <Label content="Loading..." size="l" bold={true} displayFont={true} lazy={true} />
                <Label content="Loading..." size="l" bold={true} mono={true} lazy={true} />
            </div>
        </div>
    );
};

export default React.memo(Loader);