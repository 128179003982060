import './style/Tray.css';
import React, { useEffect, useState } from 'react';
import OfferDetails from './OfferDetails';
import ShipmentDetails from './ShipmentDetails';
import ProductDetails from './ProductDetails';
import PayoutDetails from './PayoutDetails';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { addNotification, setTrayOpen } from '../../redux/keystoneSlice';
import { selectAllInventoryItemsWithData } from '../../redux/keystoneInventorySlice';
import { respondOffer } from '../../redux/keystoneInterfaceSlice';
import { useLocation } from 'react-router-dom';

const Tray = () => {
    const dispatch = useDispatch();
    const keystoneTrayData = useSelector((state) => state?.keystone?.trayData);
    const keystoneTrayType = useSelector((state) => state?.keystone?.trayType);
    const keystoneTrayOpen = useSelector((state) => state?.keystone?.trayOpen);

    const keystoneInterfaceAccessToken = useSelector((state) => state?.keystoneInterface?.accessToken);
    const keystoneInterfaceIsLoggedIn = useSelector((state) => state?.keystoneInterface?.isLoggedIn);

    const keystoneInventoryAll = useSelector(selectAllInventoryItemsWithData);
    const [productData, setProductData] = useState(null);
    const { search } = useLocation();

    const handleAccept = (trayType) => {
        if (trayType === "offer") {
            const offerId = keystoneTrayData?.id;
            dispatch(addNotification({
                page: "shipments",
                tab: 0
            }));

            dispatch(respondOffer({
                accessToken: keystoneInterfaceAccessToken,
                id: offerId,
                status: "ACCEPTED"
            }));
        }
    }

    const handleReject = (trayType) => {
        if (trayType === "offer") {
            const offerId = keystoneTrayData?.id;
            dispatch(setTrayOpen(false));
            dispatch(respondOffer({
                accessToken: keystoneInterfaceAccessToken,
                id: offerId,
                status: "REJECTED"
            }));
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(search);
        if (params.get('id')) {
            dispatch(setTrayOpen(true));
        } else {
            dispatch(setTrayOpen(false));
        }
    }, [dispatch, search]);

    useEffect(() => {
        if (keystoneTrayType === "product") {
            const params = new URLSearchParams(search);
            const id = params.get('id');
            if (id) {
                setProductData(keystoneInventoryAll?.find(item => item.id === id));
            }
        }

    }, [search, keystoneTrayType, keystoneInventoryAll]);

    return (
        <div id="keystone-tray" className={`product-vertical-container align-start v-gap-s ${keystoneTrayOpen ? 'open' : 'closed'} ${keystoneInterfaceIsLoggedIn ? "" : "over-everything"}`}>
            {keystoneTrayType === "offer" && (
                <OfferDetails
                    handleAccept={handleAccept}
                    handleReject={handleReject} />
            )}
            {keystoneTrayType === "shipment" && (
                <ShipmentDetails
                    handleAccept={handleAccept}
                    handleReject={handleReject} />
            )}
            {keystoneTrayType === "payout" && (
                <PayoutDetails
                    handleAccept={handleAccept}
                    handleReject={handleReject} />
            )}
            {keystoneTrayType === "product" && (
                <ProductDetails 
                    productData={ productData }/>
            )}
        </div>
    );
};

export default React.memo(Tray);