import Button from './Button';
import './style/BoxMinimumModal.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowModal, setBoxMinimum } from '../../redux/keystoneInterfaceSlice';
import Label from './Label';
import Input from './Input';
import { useEvent as useEffectEvent } from '@reactuses/core';

const BoxMinimumModal = () => {
    const dispatch = useDispatch();
    const keystoneInterfaceAccessToken = useSelector((state) => state?.keystoneInterface?.accessToken);
    const keystoneInterfaceSettingBoxMinimum = useSelector((state) => state?.keystoneInterface?.settingBoxMinimum);
    const keystoneInterfaceErrorSettingBoxMinimum = useSelector((state) => state?.keystoneInterface?.errorSettingBoxMinimum);
    
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        e.preventDefault();
        setInputValue(e.target.value);
    };

    const handleUpdateBoxMinimum = useEffectEvent(() => {
        dispatch(setBoxMinimum({ accessToken: keystoneInterfaceAccessToken, amount: inputValue }));
        setLoading(true);
    });

    useEffect(() => {
        if (loading) {
            if (keystoneInterfaceErrorSettingBoxMinimum || !keystoneInterfaceSettingBoxMinimum) { 
                setLoading(false);
                dispatch(setShowModal(false));
            }
        }
    }, [dispatch, keystoneInterfaceErrorSettingBoxMinimum, keystoneInterfaceSettingBoxMinimum, loading]);

    return (
        <div className="keystone-box-minimum-modal modal-wrapper">
            <div className="modal-header flex-1 pad-s pad-h-l space-between align-center">
                <Label content="Minimum Shipment Value" size="m" bold="true" displayFont={true} />
                <Button label="Close" onClick={() => dispatch(setShowModal(false))} />
            </div>
            <div className="pad-l no-pad-bot">
                <div className="product-horizontal-container mobile-vertical h-gap-m v-gap-m flex-1 justify-end">
                    <div className="product-vertical-container v-gap-xs flex-1">
                        <Label content="Minimum Shipment Value" size="s" bold="true" displayFont={true} />
                        <Input placeholder="$0.00" type="number" value={inputValue} onChange={(e) => handleInputChange(e)} large={true} alignRight={true} />
                    </div>
                </div>
            </div>
            <div className="modal-footer flex-1 justify-end pad-l h-gap-l">
                <div className="product-vertical-container">
                    <div className="product-horizontal-container h-gap-s">
                        <Button brand={true} label="Save" large="true" onClick={() => handleUpdateBoxMinimum()} loading={loading} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(BoxMinimumModal);