import './style/TrayHeader.css';
import React from 'react';
import { } from '../../redux/keystoneInventorySlice';
import { } from '../../redux/keystoneInterfaceSlice';
import Button from './Button';
import Label from './Label';
import { useNavigate, useParams } from 'react-router-dom';

const TrayHeader = ({ type, label }) => {
    const navigate = useNavigate();
    const { page } = useParams();

    const handleCloseTray = () => {
        navigate(`/${page}`);
    };

    return (
        <div className="keystone-tray-header product-vertical-container width-100 space-between pad-l v-gap-display">
            <Button
                label="Dismiss"
                onClick={handleCloseTray}
                textColor="white"
                onBrand={true} />
            <div className="product-vertical-container v-gap-xs">
                <Label content={type} size="m" bold={true} displayFont={true} invert={true} />
                <Label content={label} size="xl" mono={true} uppercase={true} invert={true} />
            </div>
        </div>
    );
};

export default React.memo(TrayHeader);