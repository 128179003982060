import Button from './Button';
import './style/SetWarehouseLocationModal.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowModal, setWarehouseLocations } from '../../redux/keystoneInterfaceSlice';
import Label from './Label';
import { AddressElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import info from '../../media/info32@3x.png';
import Icon from './Icon';
import SFMono from '../../assets/fonts/SF-Mono-Regular.otf';

const stripePromise = loadStripe(window.location.origin.includes('local') ? 'pk_test_51Ogrl2EuTsV1gDvd9ZDMooavi2UlpzbWJlJD8SfsQaQTHp5xEczx08oJFUUqEFREgclCn9sx8X35z9iv6l6kYXqS00ehr7MB49' : '');

const options = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
        },
        {
            family: 'SF Mono',
            src: `url(https://app.trymaterialize.com${SFMono}) format("opentype")`,
            weight: '500',
        }
    ],
    appearance: {
        theme: 'stripe',
        variables: {
            fontFamily: `'Poppins', sans-serif`,
            '--ks-blue-dark': '#6f7aa5',
            '--ks-box-shadow-shallow': '0 2px 8px rgba(230, 232, 240, 0.5)',
            '--ks-divider': '#E6E8F0',
        },
        rules: {
            '.Label': {
                fontFamily: 'Poppins',
                fontWeight: '600',
                fontSize: '12px',
                lineHeight: '14px',
                color: 'black',
            },
            '.Input': {
                fontFamily: 'SF Mono',
                paddingTop: '9px',
                paddingBottom: '9px',
                paddingLeft: '12px',
                paddingRight: '12px',
                fontSize: '14px',
                lineHeight: '16px',
                borderRadius: '4px',
                backgroundColor: 'white',
                boxShadow: '0 2px 8px rgba(230, 232, 240, 0.5)',
                outline: 'none',
                border: '1px solid #E6E8F0',
            },
            '.Input:focus': {
                backgroundColor: 'white',
                borderRadius: '4px',
                border: '1px solid #6f7aa5',
                boxShadow: 'none',
            },
            '.Input::placeholder': {
                color: '#6f7aa5',
            }
            
        }
    }
};


// var references
// :root {
//     --ks-brand-color: #FFCC16;
//     --ks-brand-color-hover: #ffd746;
//     --ks-brand-color-light: #fcefc0;
//     --ks-brand-color-dark: #e09f07;
//     --ks-divider: #E6E8F0;
//     --ks-divider-transparent: rgba(0,0,0,0.24);
//     --ks-divider-transparent-light: rgba(0,0,0,0.12);
//     --ks-divider-light: #f2f4f8;
//     --ks-divider-superlight: #f6f8fb;
//     --ks-blue: #DDECEC;
//     --ks-mint: #E6F4F1;
//     --ks-divider-dark: #b9bfd6;
//     --ks-positive: #079f80;
//     --ks-positive-text: rgb(38, 92, 38);
//     --ks-positive-background: rgb(196, 236, 196);
//     --ks-negative-text: #b60b0b;
//     --ks-negative-background: #f7e0e2;
//     --ks-negative: #e64040;
//     --ks-brand-secondary: #372d5b;
//     --ks-blue-dark: #6f7aa5;
//     --ks-box-shadow: 0 12px 32px rgba(230, 232, 240, 0.5);
//     --ks-box-shadow-up: 0 -12px 32px rgba(230, 232, 240, 0.5);
//     --ks-box-shadow-gray: 0 12px 24px rgba(0, 0, 0, 0.04);
//     --ks-box-shadow-shallow: 0 2px 8px rgba(230, 232, 240, 0.5);
//     --ks-text-color: #333;
//     --ks-negative-color: #e64040;
//     --ks-dark-blue: #1c6fff;
//     --ks-green: #00b894;
//     --ks-primary-gradient: linear-gradient(      /* Gradient for over 50% */
//         to top,
//         #6c9cef,
//         #0857de,
//         #ffffff
//     );
//     --ks-under-gradient: linear-gradient(        /* Gradient for under 50% */
//         to top,
//         #ff6666,
//         #ff9999,
//         #ffffff
//     );
//     --ks-center-line-color: #003D33;             /* Darker line for contrast */
//     --ks-divider: #E6E8F0;                       /* Keeping the original */
// }

// input
// border-radius: 4px;
// background-color: white;
// box-shadow: var(--ks-box-shadow-shallow);
// border: 1px solid var(--ks-divider);

// input: focus
// background-color: white;
// border-radius: 4px;
// border: 1px solid var(--ks-blue-dark);


const SetWarehouseLocationModal = ({ handleDimiss }) => {
    const dispatch = useDispatch();
    const keystoneInterfaceAccessToken = useSelector((state) => state?.keystoneInterface?.accessToken);
    const warehouseName = useSelector((state) => state?.keystoneInterface?.editingWarehouseName);
    const keystoneInterfaceCompanyWarehouseLocations = useSelector((state) => state?.keystoneInterface?.companyWarehouseLocations);
    const keystoneInterfaceSettingWarehouseLocations = useSelector((state) => state?.keystoneInterface?.settingWarehouseLocations);
    const keystoneInterfaceSetWarehouseLocationSuccessfully = useSelector((state) => state?.keystoneInterface?.setWarehouseLocationsSuccessfully);
    const [address, setAddress] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);

    const existingWarehouseAddress = keystoneInterfaceCompanyWarehouseLocations?.[warehouseName]?.address;


    const handleAddressChange = (event) => {
        console.log(event);
        setAddress(event);
    };

    const setWarehouseLocation = () => {
        const { line1, line2, city, country, postal_code, state } = address?.value.address;
        const formattedAddress = {
            name: address?.value.name,
            line1,
            line2,
            city,
            region: state,
            postal_code,
            country
        };
        dispatch(setWarehouseLocations({ accessToken: keystoneInterfaceAccessToken, warehouse_locations: {
            ...keystoneInterfaceCompanyWarehouseLocations,
            [warehouseName]: {
                address: formattedAddress
            }
        }}))
    };

    useEffect(() => {
        if (keystoneInterfaceSetWarehouseLocationSuccessfully) {
            if (handleDimiss) {
                handleDimiss();
            } else {
                dispatch(setShowModal(false));
            }
        }
    }, [dispatch, handleDimiss, keystoneInterfaceSetWarehouseLocationSuccessfully])
    


    return (
        <div className="keystone-locations-setup-modal product-vertical-container modal-wrapper overflow-x-visible">
            <div className="modal-header product-horizontal-container pad-s pad-h-l space-between align-center">
                <Label content="Edit Warehouse Location" size="m" bold="true" displayFont={true} />
                <Button label="Close" onClick={() => dispatch(setShowModal(false))} />
            </div>
            <div className="product-vertical-container flex-1 space-between v-gap-s">
                <div className="product-vertical-container v-gap-m pad-m pad-h-l">
                    <div className='product-horizontal-container h-gap-m flex-1 justify-center'>
                        <div className='product-horizontal-container h-gap-xs align-center'>
                            <Label displayFont={true} bold={true} content={'Warehouse Name:'} size="s" />
                            <Label displayFont={true} bold={false} mono={true} content={warehouseName} size="s" />
                            <Icon icon={info} size="10" color="#b9bfd6" handleMouseEnter={(e) => setShowTooltip(true)} handleMouseLeave={(e) => setShowTooltip(false)}>
                                    {showTooltip && (<div className="modal-tooltip" style={{marginLeft: '14px', marginTop: '14px'}}><Label content={'Your unique name for this warehouse location. This should not change between updates.'} mono={true} size="s" /></div>)}
                            </Icon>
                        </div>
                    </div>
                    <Elements stripe={stripePromise} options={options}>
                        <AddressElement
                            onChange={handleAddressChange}
                            options={{
                                mode: 'shipping',
                                display: {
                                    name: 'full',
                                },
                                autocomplete: {
                                    mode: 'google_maps_api',
                                    apiKey: 'AIzaSyD0_wONkOnNgrtMp4tUY4FsLUawwZvvaPk',
                                },
                                defaultValues: {
                                    name: existingWarehouseAddress?.name,
                                    address: {
                                        line1: existingWarehouseAddress?.line1,
                                        line2: existingWarehouseAddress?.line2,
                                        city: existingWarehouseAddress?.city,
                                        state: existingWarehouseAddress?.region,
                                        postal_code: existingWarehouseAddress?.postal_code,
                                        country: existingWarehouseAddress?.country
                                    }
                                }
                            }}
                        />
                    </Elements>
                </div>
                <div className="product-horizontal-container justify-end pad-l h-gap-s">
                    <Button brand={true} disabled={!address?.complete} loading={keystoneInterfaceSettingWarehouseLocations} label="Submit" large="true" onClick={setWarehouseLocation} />
                </div>
            </div>
        </div>
    );
};

export default React.memo(SetWarehouseLocationModal);