import './style/TabGroup.css';
import React, { useCallback } from 'react';
import Tab from './Tab';
import { useParams } from 'react-router-dom';

const TabGroup = ({ tabs, navigation = false, handleClick, currentTab }) => {
    const { tabName } = useParams();

    const isActive = useCallback((tab) => {
        return navigation ? tabName === tab.replaceAll(" ", "-").toLowerCase() : currentTab === tab;
    }, [navigation, tabName, currentTab]);

    return tabs && (
        <div className={`keystone-tab-group product-horizontal-container h-gap-s flex-1 ${navigation ? "justify-center" : "justify-end"}`}>
            {tabs.map((tab, index) => {
                return <Tab key={index} index={index} label={tab} active={isActive(tab)} navigation={navigation} handleClick={handleClick} />
            })}
        </div>
    );
};

export default React.memo(TabGroup);