import './style/InfoBlock.css';
import React, { useMemo } from 'react';
import { } from '../../redux/keystoneSlice';
import { } from '../../redux/keystoneInventorySlice';
import { formatToCurrency, formatToCurrencyExpanded } from '../../util/Calculate';
import Label from './Label';
import ChipRounded from './ChipRounded';
import HeaderVisual from './HeaderVisual';

const InfoBlock = (
    {
        index,
        primaryLabel,
        primaryFormat = "text",
        primaryMono = false,
        secondaryLabel,
        secondaryLabelSingleLine,
        primaryLabelAlternate,
        secondaryLabelAlternate,
        showAlternate = false,
        flex,
        processing = false,
        noMatch = false,
        alignRight = false,
        padRight = false,
        padLeft = false,
        hiddenOnMobile = false,
        hidden = false,
        tag = {
            label: "",
            secondary: false,
            format: "text",
            tooltip: false,
            tooltipIcon: null,
            tooltipContent: null
        },
        tags = null,
    }
) => {
    let label = showAlternate ? primaryLabelAlternate : primaryLabel;
    const alternateLabel = showAlternate ? secondaryLabelAlternate : secondaryLabel;
    if (label !== "") {
        label = primaryFormat === "usd-extended" ? formatToCurrencyExpanded(label) :
            primaryFormat === "usd" ? formatToCurrency(label) : label;
    }

    const renderedTags = useMemo(() => {
        if (!tags || tags.length === 0) return null;
    
        return tags.map((tag, index) => {
            if (tag && tag.label !== "" && !tag.tooltip) {
                return (
                <ChipRounded
                    key={index}
                    index={index}
                    label={tag.format === "usd" ? formatToCurrency(tag.label) : tag.label}
                    positive={!tag.secondary}
                    secondary={tag.secondary}
                    small={true}
                />
                );
            } else if (tag && tag.tooltip) {
                return (
                <HeaderVisual
                    key={index}
                    index={index}
                    tooltip={tag.tooltipContent}
                    displayStyle={false}
                    icon={tag.tooltipIcon}
                />
                );
            } else {
                return null;
            }
        });
    }, [tags]);
  

    return (
        <div key={index ? index : null} className={`keystone-info-block product-horizontal-container v-gap-xxs ${flex ? "flex-1" : "min-160"} ${alignRight === true ? "justify-end" : ""} ${padRight === true ? "pad-right-24" : ""} ${padLeft === true ? "pad-left-24" : ""} ${hiddenOnMobile === true ? "mobile-hide" : ""} ${hidden === true ? "hide" : ""}`}>
            {!processing && (
                <div className={`keystone-info-block product-vertical-container v-gap-xxs ${alignRight === true ? "right-align" : ""}`}>
                    {label && (
                        <div className={`product-horizontal-container align-center h-gap-xs ${alignRight ? "justify-end" : ""}`}>
                            <Label content={label || ""} size="s" bold={true} mono={primaryMono} displayFont={!primaryMono} />
                            {renderedTags}
                        </div>
                    )}
                    {alternateLabel && <Label content={alternateLabel} size="s" mono={true} singleLine={secondaryLabelSingleLine} />}
                </div>
            )}
            {processing && !noMatch && (
                <div className={`keystone-info-block product-vertical-container v-gap-xxs`}>
                    <Label content="Processing" size="s" bold={true} mono={primaryMono} secondary={true} />
                </div>
            )}
            {processing && noMatch && (
                <div className={`keystone-info-block product-vertical-container v-gap-xxs`}>
                    <Label content="No Match" size="s" bold={true} mono={primaryMono} secondary={true} />
                </div>
            )}
        </div>
    );
};

export default React.memo(InfoBlock);