import './style/LinkedCard.css';
import React, { useState, useEffect } from 'react';
import Icon from './Icon';
import Divider from './Divider';
import Label from './Label';

const LinkedCard = ({ label, icon, details, first = false, last = false, content, color = "#000" }) => {
    const [localColor, setLocalColor] = useState(color);

    useEffect(() => {
        if (color === "green") {
            setLocalColor("#00b894");
        }

        if (color === "orange") {
            setLocalColor("#e17055");
        }

        if (color === "gray") {
            setLocalColor("#b9bfd6");
        }
    }, [color]);
    return (
        <div className="keystone-linked-card product-horizontal-container align-center relative">
            <div className="linked-card-icon-wrapper">
                <Icon icon={icon} size={24} z={2} color={localColor} />
            </div>
            <Divider 
                width={32}
            />
            <div className="keystone-linked-card keystone-card pad-s product-vertical-container v-gap-xs width-100">
                {details && (
                    <Label content={details} size="s" bold={true} uppercase={true} mono={true} />
                )}
                {label && (
                    <Label content={label} size="s" bold={true} uppercase={true} mono={true} secondary={true} />
                )}
                {content}
            </div>
            <Divider 
                absolute={true}
                vertical={true}
                first={first}
                last={last}
            />
        </div>
    );
};

export default React.memo(LinkedCard);