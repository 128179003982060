import './style/Label.css';
import React from 'react';
import { } from '../../redux/keystoneSlice';
import { } from '../../redux/keystoneInventorySlice';

const Label = ({
    bullet = false,
    index,
    shakeOnShow = false,
    customRef = null,
    content = "",
    size = "m",
    line,
    color= "black",
    bold = false,
    mono = false,
    secondary = false,
    onBrand = false,
    maxContent = false,
    uppercase = false,
    invert = false,
    center = false,
    style = {},
    displayFont = false,
    positive = false,
    negative = false,
    right = false,
    hide = false,
    singleLine = false,
    capitalize = false,
    lazy = false,
    onClick = null,
    emStyle = 'black'
}) => {
    return (
        <p ref={customRef ? customRef : undefined} index={index ? index : null} onClick={onClick} className={`keystone-label ${!displayFont ? "sf-pro" : ""} size-${size} ${bold ? "bold" : "medium"} ${line ? "line-" + line : ""} ${mono ? "sf-mono" : ""} ${secondary ? "secondary" : ""} ${onBrand ? "on-brand" : ""} ${maxContent ? "max-content" : ""} ${uppercase ? "uppercase" : ""} ${invert ? "invert" : ""} ${center ? "center-text" : ""} ${positive ? "positive" : ""} ${negative ? "negative" : ""} ${right ? "right-align" : ""} ${color === "brand" ? "brand-color" : color ==="white" ? "white" : ""}  ${hide ? "hide" : ""} ${singleLine ? "single-line" : ""} ${capitalize ? "capitalize" : ""} ${lazy ? "lazy" : ""} ${shakeOnShow ? 'shake-once':''} ${emStyle ? 'em-style-' + emStyle : ''}`} style={{alignItems: size='m' ? 'left' : right}}>
            {bullet ? <span id="list-bullet">• </span> : ''}{content ? content : ""}
        </p>
    );
};

export default React.memo(Label);