import Label from './Label';
import './style/Action.css';
import React from 'react';

const Action = ({ reference, onClick, label, negative = false }) => {
    return (
        <div ref={reference} className={`keystone-action ${negative ? "negative" : ""}`} onClick={onClick}>
            <Label content={label} size="s" bold={true} displayFont={true} />
        </div>
    );
};

export default React.memo(Action);