import './style/Payouts.css';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { openTrayTypeWithData } from '../../redux/keystoneSlice';
import { makeSelectInventoryItemById } from '../../redux/keystoneInventorySlice';
import Row from './Row';
import Placeholder from './Placeholder';
import { useEvent as useEffectEvent } from '@reactuses/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchPayouts, normalizeTabName } from '../../redux/keystoneInterfaceSlice';

const Payouts = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const keystoneInterfacePayoutsTabs = useSelector((state) => state?.keystoneInterface?.payoutsTabs);
    const keystoneInterfaceAccessToken = useSelector((state) => state?.keystoneInterface?.accessToken);
    const keystoneInventoryPayouts = useSelector((state) => state?.keystoneInventory?.payouts);
    const { page, tabName } = useParams();

    const keystoneInterfacePayoutsTab = keystoneInterfacePayoutsTabs.map(normalizeTabName).indexOf(normalizeTabName(tabName));
    useEffect(() => {
        if (keystoneInterfacePayoutsTab < 0) {
            navigate(`/${page}/${normalizeTabName(keystoneInterfacePayoutsTabs?.[0])}`);
        }
    }, [keystoneInterfacePayoutsTab, keystoneInterfacePayoutsTabs, page, navigate]);


    const { search } = useLocation();
    const selectedObjectId = new URLSearchParams(search).get('id') || null;
    const selectInventoryItemById = useMemo(() => makeSelectInventoryItemById(), []);
    const selectedObject = useSelector(state => selectInventoryItemById(state, selectedObjectId));

    useEffect(() => {
        fetchPayoutData();
    }, []);

    const fetchPayoutData = useEffectEvent(() => {
        dispatch(fetchPayouts({ accessToken: keystoneInterfaceAccessToken }));
    });

    useEffect(() => {
        if (selectedObject) {
            dispatch(openTrayTypeWithData({
                trayOpen: true,
                trayData: selectedObject,
                trayType: "payout"
            }));
        }
    }, [dispatch, selectedObject]);

    return (
        <div className="keystone-shipments list-container product-vertical-container pad-m v-gap-s flex-1">
            {keystoneInventoryPayouts?.filter(payout =>
                (keystoneInterfacePayoutsTab === 0 && payout.status === "ON_HOLD") || 
                (keystoneInterfacePayoutsTab === 1 && payout.status === "SENT") || 
                (keystoneInterfacePayoutsTab === 2 && payout.status === "COMPLETED")
            ).map((payout, index) => (
                <Row
                    key={payout.id}
                    index={payout.id}
                    primaryLabel={`Payout #${payout.id}`}
                    secondaryLabel={`${new Date(payout.created_at).toLocaleDateString()}`}
                    navigateOnClick={true}
                    tags={[{
                        label: payout.amount,
                        secondary: false,
                        format: "usd"
                    }]} />
            ))}
            {(keystoneInterfacePayoutsTab === 0 &&
            !keystoneInventoryPayouts?.some(payout => (
                payout.status === "ON_HOLD"
            ))) && (
                <Placeholder label="No payouts on hold" />
            )}
            {(keystoneInterfacePayoutsTab === 1 && !keystoneInventoryPayouts?.some(payout => payout.status === "SENT" )) && (
                <Placeholder label="No payouts sent" />
            )}
            {keystoneInterfacePayoutsTab === 2 &&
            !keystoneInventoryPayouts?.some(payout => (
                payout.status === "COMPLETED"
            )) && (
                <Placeholder label="No completed payouts" />
            )}
        </div>
    );
};

export default React.memo(Payouts);