import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const getApiBaseUrl = () => {
    return window.location.origin.includes('local') ? 'http://localhost:8080/keystone' : 'https://api.trymaterialize.com/keystone';
}

export const fetchMagicLinkAccessToken = createAsyncThunk(
    'magicLink/fetchMagicLinkAccessToken',
    async ({ code }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/magic_link/verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code }),
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchUser = createAsyncThunk(
    'user/fetchUser',
    async ({ accessToken }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/user`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


const addCall = (call, calls) => {
    // Get the current time
    const currentTime = new Date().getTime();

    // Filter calls that are newer than 3000 milliseconds and check for uniqueness
    const updatedCalls = calls.filter(item => {
        // Maintain calls that are younger than 3000 ms and different from the new call
        // If it's a duplicate (same action and type), remove it
        return (currentTime - item.time < 3000) &&
               !(item.action === call.action && item.type === call.type);
    });

    // Add the new call
    updatedCalls.push({
        ...call,
        type: call.type,
        action: call.action,
        status: call.status,
        time: new Date().getTime()
    });

    return updatedCalls;
};

export const keystoneSlice = createSlice({
    name: 'keystone',
    initialState: {
        view: "default",
        trayOpen: false,
        temporaryAccessToken: "",
        magicLinkCode: null,
        user: null,
        calls: [],
        auth: {
            redirectPage: null
        },
        userPreferences: {
            sortBy: 'Score',
        },
        team: [
            {
                name: "John Doe",
                role: "Admin",
                email: "john@doe.com"
            },
            {
                name: "Jane Doe",
                role: "Viewer",
                email: "jane@doe.com"
            }
        ],
        acceptedOffers: [],
        rejectedOffers: [],
        shippedOffers: [],
        notifications: [],
        offers: [{
            offer_id: 1,
            offer_total_price: 10427.27,
            offer_total_quantity: 19150,
            offer_percent_of_unit_cost: 0.2366,
            offer_region: "California",
            offer_country: "United States",
            items: [
                {
                    internal_item: "G5143-128",
                    internal_part_number: "MAAMSS0042TR",
                    internal_description: "Amp, BB CATV 50-1000 MHz, MAAMSS0042",
                    internal_manufacturer: "Richardson RFPD, Inc",
                    offer_unit_price: 0.08000,
                    offer_quantity: 441,
                    offer_total_price: 35.28000,
                    offer_percent_of_unit_cost: 0.0382,
                },
                {
                    internal_item: "G5411-500",
                    internal_part_number: "M3062LFGPGP#U3C",
                    internal_description: "Microcomputer, 16 Bit, CMOS, P-LQFP100",
                    internal_manufacturer: "Renesas Electronics, Inc.",
                    offer_unit_price: 0.96000,
                    offer_quantity: 1724,
                    offer_total_price: 1655.04000,
                    offer_percent_of_unit_cost: .3029,
                },
                {
                    internal_item: "G5169-067",
                    internal_part_number: "LTC6800HDD#PBF",
                    internal_description: "Amp,Instru Rail/Rail In/Output,DFN8,ROHS",
                    internal_manufacturer: "ANALOG DEVICES INC.",
                    offer_unit_price: 0.28000,
                    offer_quantity: 1740,
                    offer_total_price: 487.20000,
                    offer_percent_of_unit_cost: .1678,
                },
                {
                    internal_item: "G5411-4211",
                    internal_part_number: "LTC4211IS8#PBF",
                    internal_description: "Cntl,Hot Swap,Multifunction Current Cntl",
                    internal_manufacturer: "ANALOG DEVICES INC.",
                    offer_unit_price: 0.40000,
                    offer_quantity: 885,
                    offer_total_price: 354.00000,
                    offer_percent_of_unit_cost: .2014,
                },
                {
                    internal_item: "G5009-001",
                    internal_part_number: "LTC1923EUH#PBF",
                    internal_description: "Controller,High Eff. TE Cooler,QFN32,PBF",
                    internal_manufacturer: "ANALOG DEVICES INC.",
                    offer_unit_price: 2.80000,
                    offer_quantity: 990,
                    offer_total_price: 2772.00000,
                    offer_percent_of_unit_cost: .3174,
                },
                {
                    internal_item: "G5654-057",
                    internal_part_number: "LTC1487CS8#PBF",
                    internal_description: "IC,TRANSCVR,RS422/RS485,250Kbps,SO8",
                    internal_manufacturer: "ANALOG DEVICES INC.",
                    offer_unit_price: 0.38400,
                    offer_quantity: 10608,
                    offer_total_price: 4073.47200,
                    offer_percent_of_unit_cost: .2413,
                },
                {
                    internal_item: "G5654-6106",
                    internal_part_number: "LT6106HS5#TRMPBF",
                    internal_description: "IC,CONTROLLER,HI SIDECURRENTSENSE,SOT-23",
                    internal_manufacturer: "ANALOG DEVICES INC.",
                    offer_unit_price: 0.36000,
                    offer_quantity: 231,
                    offer_total_price: 83.16000,
                    offer_percent_of_unit_cost: .2649,
                },
                {
                    internal_item: "G5654-060",
                    internal_part_number: "LT1964ES5-BYP#TRPBF",
                    internal_description: "IC,VOLTAGE REG,NEG,ADJ,200mA,TSOT-23",
                    internal_manufacturer: "ANALOG DEVICES INC.",
                    offer_unit_price: 0.36000,
                    offer_quantity: 1132,
                    offer_total_price: 407.52000,
                    offer_percent_of_unit_cost: .2666,
                },
                {
                    internal_item: "G5088-040",
                    internal_part_number: "LT1376IS8-5#TRPBF",
                    internal_description: "Regulator, Switching, 5V, LT1376IS8-5",
                    internal_manufacturer: "ANALOG DEVICES INC.",
                    offer_unit_price: 0.4000,
                    offer_quantity: 1399,
                    offer_total_price: 559.60000,
                    offer_percent_of_unit_cost: .1006,
                }
            ]
        },
        {
            offer_id: 2,
            offer_total_price: 38169.48,
            offer_total_quantity: 42933,
            offer_percent_of_unit_cost: 0.0688,
            offer_region: "California",
            offer_country: "United States",
            items: [{
                internal_item: "G5654-057",
                internal_part_number: "LTC1487CS8#PBF",
                internal_description: "IC,TRANSCVR,RS422/RS485,250Kbps,SO8",
                internal_manufacturer: "ANALOG DEVICES INC.",
                offer_unit_price: 0.95900,
                offer_quantity: 10608,
                offer_total_price: 10173.07200,
                offer_percent_of_unit_cost: 0.6026,
            },
            {
                internal_item: "G5291-007",
                internal_part_number: "MAX1978ETM+",
                internal_description: "IC,Integrated TempCntlPeltier Mod,ROHS",
                internal_manufacturer: "MAXIM / DALLAS DIRECT",
                offer_unit_price: 1.00100,
                offer_quantity: 22541,
                offer_total_price: 22563.54100,
                offer_percent_of_unit_cost: 0.0449
            },
            {
                internal_item: "G5687-001",
                internal_part_number: "LFXP3C-3TN100C",
                internal_description: "FPGA, 62IO,Non-Volatile, 3.3V, 100TQFP",
                internal_manufacturer: "LATTICE SEMICONDUCTOR",
                offer_unit_price: 0.81480,
                offer_quantity: 2697,
                offer_total_price: 2197.51560,
                offer_percent_of_unit_cost: 0.1051
            },
            {
                internal_item: "G5775-3100",
                internal_part_number: "MAX3100EEE+",
                internal_description: "UART, SPI/uWire compatable, RS232, RS485",
                internal_manufacturer: "MAXIM / DALLAS DIRECT",
                offer_unit_price: 0.38500,
                offer_quantity: 5363,
                offer_total_price: 2064.75500,
                offer_percent_of_unit_cost: 0.2099
            },
            {
                internal_item: "G5411-500",
                internal_part_number: "M3062LFGPGP#U3C",
                internal_description: "Microcomputer, 16 Bit, CMOS, P-LQFP100",
                internal_manufacturer: "Renesas Electronics, Inc.",
                offer_unit_price: 0.67900,
                offer_quantity: 1724,
                offer_total_price: 1170.59600,
                offer_percent_of_unit_cost: 0.2142
            }]
        }]
    },
    reducers: {
        removeTeamMember: (state, action) => {
            state.team = state.team.filter(member => member.email !== action.payload.email);
        },
        updateTeamMemberRole: (state, action) => {
            state.team = state.team?.map(member => member.email === action.payload.email ? { ...member, role: action.payload.role } : member);
        },
        updateUserToDelete: (state, action) => {
            state.userToDelete = action.payload.member;
        },
        updateUserToChange: (state, action) => {
            state.userToChange = action.payload?.member;
        },
        updateRoleToChange: (state, action) => {
            state.roleToChange = action.payload.role;
        },
        setTrayOpen: (state, action) => {
            state.trayOpen = action.payload;
        },
        setTrayData: (state, action) => {
            state.trayData = action.payload;
        },
        openTrayTypeWithData: (state, action) => {
            state.trayOpen = action.payload.trayOpen;
            state.trayData = action.payload.trayData;
            state.trayType = action.payload.trayType;
        },
        setNotifications: (state, action) => {
            state.notifications = action.payload;
        },
        setOffersAccepted: (state, action) => {
            state.offersAccepted = action.payload;
        },
        setCompany: (state, action) => {
            state.company = action.payload;
        },
        addNotification: (state, action) => {
            state.notifications.push(action.payload);
        },
        addAcceptedOffer: (state, action) => {
            state.acceptedOffers.push(action.payload);
        },
        addRejectedOffer: (state, action) => {
            state.rejectedOffers.push(action.payload);
        },
        setProductData: (state, action) => {
            state.productData = action.payload;
        },
        setMagicLinkCode: (state, action) => {
            state.magicLinkCode = action.payload;
        },
        setTemporaryAccessToken: (state, action) => {
            state.temporaryAccessToken = action.payload;
        },
        addShippedOffer: (state, action) => {
            state.shippedOffers.push(action.payload);
        },
        invertSetting: (state, action) => {
            state[action.payload] = !state[action.payload];
        },
        setSelectedInventoryId: (state, action) => {
            state.selectedInventoryId = action.payload;
        },
        setUserPreferences: (state, action) => {
            state.userPreferences = action.payload;
        },
        setView: (state, action) => {
            state.view = action.payload;
        },
        setAuthRedirectPage: (state, action) => {
            state.auth.redirectPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMagicLinkAccessToken.pending, (state) => {
                state.fetchingAccessToken = true;
                state.calls = addCall({
                    type: 'token',
                    action: 'fetch',
                    status: 'pending',
                    user: false
                }, state.calls);

                state.errorFetchingAccessToken = null;
            })
            .addCase(fetchMagicLinkAccessToken.fulfilled, (state, action) => {
                state.fetchingAccessToken = false;
                state.calls = addCall({
                    type: 'token',
                    action: 'fetch',
                    status: 'fulfilled',
                    user: false
                }, state.calls);

                state.temporaryAccessToken = action.payload.access_token;
            })
            .addCase(fetchMagicLinkAccessToken.rejected, (state, action) => {
                state.fetchingAccessToken = false;
                state.calls = addCall({
                    type: 'token',
                    action: 'fetch',
                    status: 'rejected',
                    user: false
                }, state.calls);

                state.errorFetchingAccessToken = action.payload;
            });
        builder
            .addCase(fetchUser.pending, (state) => {
                state.fetchingUser = true;
                state.calls = addCall({
                    type: 'user',
                    action: 'fetch',
                    status: 'pending',
                    user: false
                }, state.calls);

                state.errorFetchingUser = null;
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.fetchingUser = false;
                state.calls = addCall({
                    type: 'user',
                    action: 'fetch',
                    status: 'fulfilled',
                    user: false
                }, state.calls);

                state.user = action.payload;
        
                if (action.payload.flags?.tos?.accepted) {
                    state.tosAccepted = true;
                }

                if (action.payload.flags?.notifications?.offers?.enabled) {
                    state.offersNotify = true;
                }

                state.hasFetchedUser = true;
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.fetchingUser = false;
                state.calls = addCall({
                    type: 'user',
                    action: 'fetch',
                    status: 'rejected',
                    user: false
                }, state.calls);

                state.errorFetchingUser = action.payload;
                state.hasFetchedUser = true;
            });
    },
});

export const { removeTeamMember } = keystoneSlice.actions;
export const { updateTeamMemberRole } = keystoneSlice.actions;
export const { updateUserToDelete } = keystoneSlice.actions;
export const { updateUserToChange } = keystoneSlice.actions;
export const { updateRoleToChange } = keystoneSlice.actions;
export const { setTrayOpen } = keystoneSlice.actions;
export const { openTrayTypeWithData } = keystoneSlice.actions;
export const { setNotifications } = keystoneSlice.actions;
export const { setOffersAccepted } = keystoneSlice.actions;
export const { setCompany } = keystoneSlice.actions;
export const { addNotification } = keystoneSlice.actions;
export const { addAcceptedOffer } = keystoneSlice.actions;
export const { addRejectedOffer } = keystoneSlice.actions;
export const { addShippedOffer } = keystoneSlice.actions;
export const { invertSetting } = keystoneSlice.actions;
export const { setProductData } = keystoneSlice.actions;
export const { setSelectedInventoryId } = keystoneSlice.actions;
export const { setUserPreferences } = keystoneSlice.actions;
export const { openPlaid } = keystoneSlice.actions;
export const { setView } = keystoneSlice.actions;
export const { setTrayData } = keystoneSlice.actions;
export const { setTemporaryAccessToken } = keystoneSlice.actions;
export const { setMagicLinkCode } = keystoneSlice.actions;
export const { setAuthRedirectPage } = keystoneSlice.actions;

export default keystoneSlice.reducer;