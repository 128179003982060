import ChipRounded from './ChipRounded';
import Label from './Label';
import './style/CellPreview.css';
import React from 'react';

const CellPreview = ({ rowNumber, columnName, value, message, first = true, last = true, primary = false, blurFadeDirection = null }) => {
    return (
        <div key={`${columnName}${value}${rowNumber}${message}`} className={`keystone-cell-preview  ${primary && 'primary'} product-horizontal-container align-start flex-1`}>
            <div className={`product-vertical-container align-center flex-1 relative`}>
                <div className={`cell-preview-column-name-wrapper pad-xxs ${last ? 'last' : ''} ${first ? 'first' : ''}`}>
                    <Label singleLine={true} content={columnName} size="s" bold={true} mono={true} secondary={true} uppercase={true} />
                </div>
                <div className={`cell-preview-value-wrapper pad-xxs ${primary && 'primary'} relative ${blurFadeDirection && ('blur-' + blurFadeDirection)} width-100 pad-xs ${last && 'last'} ${first ? 'first' : ''}`}>
                    <Label singleLine={true} content={value || 0} hide={!value} size="m" mono={true} center={true} />
                </div>
                {message && (
                    <ChipRounded label={message.includes("MISSING_REQUIRED_FIELD") ? "Missing Value" :
                        message.includes("INVALID_QUANTITY") || message.includes("INVALID_UNIT_COST") || message.includes("INVALID_MINIMUM_PRICE") ? "Invalid Number" : message} negative={true} />
                )}
            </div>
        </div>
    );
};

export default React.memo(CellPreview);