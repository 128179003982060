import './style/ScoreBar.css';
import React, { useState, useEffect } from 'react';

const ScoreBar = ({ score, gold, invert, label, minLabel, maxLabel, flex = true, padLeft = false, padRight = false }) => {
    const [fillWidth, setFillWidth] = useState(0);
    const [color, setColor] = useState('#000');
  
    useEffect(() => {
      const fillAnim = setTimeout(() => {
        const width = Math.min(score * 100, 100);
        setFillWidth(width)
        if (width > 50) {
          setColor("#FFCC16");
        }
      }, 420);
  
      return () => clearTimeout(fillAnim); // Cleanup in case of unmount
    }, [score]);
    
    return (
        <div className={`product-horizontal-container h-gap-m flex-1 align-center relative ${flex ? "flex-1" : "max-160"} ${padRight === true ? "pad-right-24" : ""} ${padLeft === true ? "pad-left-24" : ""}`}>
          {minLabel && <p className="score-bar-label-min sf-mono size-s medium uppercase">{minLabel}</p>}
          <div className={`flex-1 align-center relative ${invert && 'invert'}`}>
              <div className="bar-background">
                <div className="bar-fill" style={{ width: `${fillWidth}%`, backgroundColor: color }} />
              </div>
              {label && <p className="score-bar-label size-s medium sf-mono uppercase">{label}</p>}
          </div>
          {maxLabel && <p className="score-bar-label-max sf-mono size-s medium uppercase">{maxLabel}</p>}
        </div>
    );
};

export default React.memo(ScoreBar);