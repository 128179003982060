import './style/OfferDetails.css';
import React, { useState, useEffect } from 'react';
import Button from './Button';
import { formatToCurrency, formatToCurrencyExpanded, formatPercentWithCommas } from '../../util/Calculate';
import ScoreBar from './ScoreBar';
import Divider from './Divider';
import Icon from './Icon';
import Shipping from '../../media/shipping@3x.png';
import { useSelector } from 'react-redux';
import Label from './Label';
import TrayHeader from './TrayHeader';
import InfoBlock from './InfoBlock';
import { useNavigate, useParams } from 'react-router-dom';

const OfferDetails = ({ handleAccept, handleReject }) => {
    const keystoneTrayData = useSelector((state) => state?.keystone?.trayData);
    const keystoneTrayType = useSelector((state) => state?.keystone?.trayType);
    const keystoneInterfaceCalls = useSelector((state) => state?.keystoneInterface?.calls);

    const [acceptLoading, setAcceptLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);
    const { page, tabName } = useParams();
    const navigate = useNavigate();


    
    const handleAcceptOffer = (accept) => {
        if (accept) {
            setAcceptLoading(true);
            handleAccept(keystoneTrayType);
        } else {
            setRejectLoading(true);
            handleReject(keystoneTrayType);
        }
    };

    useEffect(() => {
        if (keystoneInterfaceCalls?.some(item =>
            item.type === "offer" &&
            item.action === "respond" &&
            (item.status === "fulfilled" || item.status === "rejected")
        )) {
            setAcceptLoading(false);
            setRejectLoading(false);
            navigate(`/${page}/${tabName}`);
        }
    }, [keystoneInterfaceCalls, navigate, page, tabName]);

    return keystoneTrayData && (
        <div className="keystone-offer-details product-vertical-container width-100 flex-1">
            <TrayHeader
                type="Offer"
                label={`#${keystoneTrayData?.id}`}
            />
            <div className="product-vertical-container flex-1 width-100 overflow-auto">
                <div className="product-vertical-container flex-1 width-100 pad-l v-gap-m overflow-auto">
                    {keystoneTrayData?.offer_items?.map((item, index) => (
                        <div key={index} className="product-vertical-container keystone-card pad-s width-100 v-gap-m">
                            <div className="product-horizontal-container width-100 v-gap-m align-center space-between">
                                <InfoBlock
                                    primaryLabel={item.inventory_item?.manufacturer}
                                    secondaryLabel={item.inventory_item?.mpn}
                                    flex={true} />
                                <InfoBlock
                                    primaryLabel={formatToCurrency(item.total_price)}
                                    secondaryLabel={`${item.quantity} x ${formatToCurrencyExpanded(item.unit_price)}`}
                                    primaryMono={true}
                                    alignRight={true}
                                    flex={true} />
                            </div>
                            {item.percent_of_unit_cost && <ScoreBar score={item.percent_of_unit_cost / 100} label={"Returns " + formatPercentWithCommas(item.percent_of_unit_cost / 100) + " of Cost"} minLabel="$0.00" maxLabel={formatToCurrencyExpanded(item.inventory_item?.unit_value)} />}
                        </div>
                    ))}
                </div>
                <div className="product-vertical-container box-shadow-up tray-bottom">
                    <Divider />
                    <div className="product-vertical-container pad-l">
                        <div className="product-vertical-container pad-s">
                            {keystoneTrayData?.unique_locations.map((location, index) => (
                                <div key={index} className="product-horizontal-container space-between align-center">
                                    <InfoBlock
                                        primaryLabel={`${location.quantity} Items`}
                                        secondaryLabel={`${location.warehouse_name}`}
                                        flex={true} />
                                    <Icon icon={Shipping} size={32} />
                                </div>
                            ))}
                        </div>
                        <div className="product-vertical-container pad-s">
                            <ScoreBar score={keystoneTrayData?.totalOfferPercentOfUnitCost} label={"Returns " + formatPercentWithCommas(keystoneTrayData?.totalOfferPercentOfUnitCost) + " of Cost"} />
                        </div>
                        <div className="product-horizontal-container flex-1 justify-end space-between pad-s align-center h-gap-l">
                            <InfoBlock
                                primaryLabel="Transaction Fee"
                                secondaryLabel="Incl. negotiation, advertising, and shipping costs."
                                flex={true} />
                            <Label content={formatToCurrency(keystoneTrayData?.total_price * 0.16)} size="xl" mono={true} />
                        </div>
                        <div className="product-horizontal-container flex-1 justify-end space-between pad-s align-center h-gap-l">
                            <InfoBlock
                                primaryLabel="Your Return"
                                secondaryLabel="After transaction fee."
                                flex={true} />
                            <Label content={formatToCurrency(keystoneTrayData?.total_price * 0.84)} size="xl" mono={true} />
                        </div>
                    </div>
                    {keystoneTrayData?.status === "AWAITING_RESPONSE" && (
                        <div className="product-horizontal-container justify-end pad-xl no-pad-top h-gap-l">
                            <Button
                                brand={true}
                                secondary={true}
                                label="Reject Offer"
                                large={true}
                                flex={true}
                                loading={rejectLoading}
                                onClick={() => handleAcceptOffer(false)} />
                            <Button
                                brand={true}
                                label="Accept Offer"
                                large={true}
                                flex={true}
                                loading={acceptLoading}
                                onClick={() => handleAcceptOffer(true)} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default React.memo(OfferDetails);