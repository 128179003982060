import './style/ShipmentDetails.css';
import React from 'react';
import Button from './Button';
import Shipping from '../../media/shipping@3x.png';
import Icon from './Icon';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../redux/keystoneSlice';
import { markShipped } from '../../redux/keystoneInterfaceSlice';
import Label from './Label';
import TrayHeader from './TrayHeader';
import { useNavigate, useParams } from 'react-router-dom';

const ShipmentDetails = ({ handleAccept, handleReject }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const keystoneTrayData = useSelector((state) => state?.keystone?.trayData);
    const keystoneTrayType = useSelector((state) => state?.keystone?.trayType);
    const keystoneShippedOffers = useSelector((state) => state?.keystone?.shippedOffers);
    const keystoneInterfaceAccessToken = useSelector((state) => state?.keystoneInterface?.accessToken);

    const { page } = useParams();

    const handleCloseTray = () => {
        navigate(`/${page}`);
    };

    const handleMarkAsShipped = (shipmentId) => {
        dispatch(addNotification({
            page: "payouts",
            tab: 0
        }));
        dispatch(markShipped({
            accessToken: keystoneInterfaceAccessToken,
            id: shipmentId,
        }));
        handleCloseTray();
    }
    return (
        <div className="keystone-offer-details product-vertical-container flex-1 width-100">
            <TrayHeader
                type="Shipment"
                label={`#${keystoneTrayData?.id}`}
            />
            <div className="product-vertical-container flex-1 width-100 overflow-auto">
                <div className="product-vertical-container flex-1 v-gap-m pad-l overflow-auto">
                    {keystoneTrayData?.offer?.unique_locations?.map((location, index) => {
                        return (
                            <div className="product-vertical-container keystone-shipment-container pad-l v-gap-l">
                                <div className="product-horizontal-container keystone-shipment-container space-between v-gap-xs">
                                    <div className="product-vertical-container space-between">
                                        <Label content={`#${index + 1}`} size="xl" bold={true} mono={true} uppercase={true} />
                                        <Label content={`${location.warehouse_name}`} size="s" bold={true} mono={true} uppercase={true} />
                                    </div>
                                    <div className="product-vertical-container space-between">
                                        <Icon icon={Shipping} size={24} />
                                        <Label content={`${keystoneTrayData?.offer?.offer_region}, ${keystoneTrayData?.offer?.offer_country}`} size="s" bold={true} mono={true} uppercase={true} />
                                    </div>
                                </div>
                                {keystoneTrayData?.related_offer_items?.filter(offerItem => offerItem.inventory_item?.warehouse_name === location.warehouse_name).map((item, index) => (
                                    <div key={index} className="product-vertical-container width-100 v-gap-m">
                                        <div className="product-horizontal-container width-100 v-gap-m align-center space-between">
                                            <div className="product-vertical-container flex-1">
                                                <Label content={item.inventory_item?.mpn} size="s" bold={true} mono={true} uppercase={true} secondary={true} />
                                            </div>
                                            <Label content={item.quantity} mono={true} size="s" right={true} />
                                        </div>
                                    </div>
                                ))}
                                {/* <p className="sf-mono">Total Weight: {location.weightKg?.toFixed(2)}kg</p> */}
                                <div className="product-horizontal-container v-gap-s space-between">
                                    {keystoneShippedOffers?.some(shippedOffer => shippedOffer.offer_id === keystoneTrayData?.id && shippedOffer.location.warehouse_name === location.warehouse_name) ? (
                                        <Button label="Shipped" disabled={true} />
                                    ) : (
                                        <Button label="Mark Shipped" onClick={() => handleMarkAsShipped(keystoneTrayData?.id)} onBrand={true} />
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="product-vertical-container box-shadow-up tray-bottom">
                    <div className="product-horizontal-container justify-end pad-xl h-gap-l">
                        <Button
                            brand={true}
                            secondary={true}
                            label="Print Packing Slip"
                            flex={true}
                            large={true}
                            onClick={() => handleReject(keystoneTrayType)} />
                        <Button
                            brand={true}
                            label="Print Shipping Label"
                            flex={true}
                            large={true}
                            onClick={() => handleAccept(keystoneTrayType)} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ShipmentDetails);