import Icon from './Icon';
import './style/InventoryHeaderColumn.css';
import React, { useState, useEffect } from 'react';
import DownArrow from '../../media/down-arrow-thick@3x.png';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setUserPreferences } from '../../redux/keystoneSlice';

const InventoryHeaderColumn = ({ column, label, flex, lastColumn, hideMobile }) => {
    const keystoneUserPreferences = useSelector((state) => state?.keystone?.userPreferences);
    const dispatch = useDispatch();

    const [color, setColor] = useState('#b9bfd6');

    useEffect(() => {
        if (keystoneUserPreferences?.sortBy?.includes(label)) {
            setColor('#dd780d');
        } else {
            setColor('#b9bfd6');
        }
    }, [label, keystoneUserPreferences?.sortBy]);
    
    return (
        <div className={`keystone-inventory-header-column inventory-column-${column} space-between align-center ${keystoneUserPreferences?.hiddenColumns?.includes(label) ? "placeholder-width" : ""} ${hideMobile ? "mobile-hide" : ""}`}>
            <div className="product-horizontal-container h-gap-xs align-center">
                <p className="keystone-column-header size-s">{label}</p>
            </div>
            <button
                className={keystoneUserPreferences?.sortBy?.includes("ascending") && keystoneUserPreferences?.sortBy?.includes(label) ? "rotate" : label !== keystoneUserPreferences?.sortBy ? "disabled" : undefined}
                onClick={() => {
                    dispatch(setUserPreferences({ ...keystoneUserPreferences||{}, sortBy: keystoneUserPreferences?.sortBy === label ? `${label}-ascending` : label }));
                }
            }>
                <Icon icon={DownArrow} size="12" color={color} />
            </button>
        </div>
    );
};

export default React.memo(InventoryHeaderColumn);