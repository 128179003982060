import Label from './Label';
import './style/InventoryAccessFlow.css';
import React from 'react';

const InventoryAccessFlow = () => {
    return (
        <div className="keystone-upload-flow modal-wrapper">
            <div className="product-vertical-container pad-l v-gap-l">
                <Label content="reasons why we hide by default plus link to setting" />
                <Label content="no commitment to accepting offers" />
                <Label content="we automatically negotiate offers leveraging market insights" />
                <Label content="you handle shipping and handling, we pay for it" />
                <div className="product-horizontal-container justify-end h-gap-l">
                    <button className="ks-button brand">Go to Setting</button>
                </div>
            </div>
        </div>
    );
};

export default React.memo(InventoryAccessFlow);