import { useSelector } from 'react-redux';
import './style/SimpleLoader.css';
import React from 'react';

const SimpleLoader = ({ determineLoadingStateAutomatically = false, loading = false, size = 32 }) => {
    const keystoneInterfaceFetchingInventory = useSelector((state) => state?.keystoneInterface?.fetchingInventory);
    const keystoneInterfaceHasFetchedInventory = useSelector((state) => state?.keystoneInterface?.hasFetchedInventory);
    const keystoneInterfaceUploadingInventory = useSelector((state) => state?.keystoneInterface?.uploadingInventory);
    const keystoneInterfaceCommittingInventory = useSelector((state) => state?.keystoneInterface?.committingInventory);
    const keystoneInterfaceFetchingOffers = useSelector((state) => state?.keystoneInterface?.fetchingOffers);
    const keystoneInterfaceFetchingShipments = useSelector((state) => state?.keystoneInterface?.fetchingShipments);
    const keystoneInterfaceFetchingPayouts = useSelector((state) => state?.keystoneInterface?.fetchingPayouts);
    const keystoneInterfaceFetchingTeam = useSelector((state) => state?.keystoneInterface?.fetchingTeam);
    const keystoneInterfaceInvitingMember = useSelector((state) => state?.keystoneInterface?.invitingMember);

    const keystoneInterfaceFetchingUser = useSelector((state) => state?.keystoneInterface?.fetchingUser);
    const keystoneUser = useSelector((state) => state?.keystone?.user);

    let showLoader = loading;
    if (determineLoadingStateAutomatically) {
        showLoader = ((keystoneInterfaceFetchingInventory === true && !keystoneInterfaceHasFetchedInventory === 0) ||
        keystoneInterfaceUploadingInventory === true ||
        keystoneInterfaceCommittingInventory === true ||
        keystoneInterfaceFetchingOffers === true ||
        keystoneInterfaceFetchingShipments === true ||
        keystoneInterfaceFetchingPayouts === true ||
        keystoneInterfaceFetchingTeam === true ||
        (keystoneInterfaceFetchingUser && !keystoneUser) ||
        keystoneInterfaceInvitingMember === true);
    }

    return (
        <div
            className="keystone-simple-loader-wrapper"
            style={{
                opacity: showLoader ? 1 : 0,
                width: size,
                height: size,
                borderWidth: Math.floor(size / 10),
                left: `calc(50% - ${size / 2}px)`,
                top: `calc(50% - ${size / 2}px)`,
                padding: Math.floor(size / 6),
            }}>
            <div
                className="keystone-simple-loader"
                style={{
                    borderWidth: Math.floor(size / 10),
                }}
            ></div>
        </div>
    );
};

export default React.memo(SimpleLoader);