import './style/Select.css';
import React from 'react';
import { } from '../../redux/keystoneSlice';
import { } from '../../redux/keystoneInventorySlice';

const Select = ({ options, value, onChange, onWhite = true }) => {
    return (
        <select className={`keystone-select sf-mono size-m ${(onWhite ? " on-white" : "") }`} value={value} onChange={onChange}>
            {options.map((option, index) => (
                <option key={index} value={option}>{option}</option>
            ))}
        </select>
    );
};

export default React.memo(Select);