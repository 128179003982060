import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Toast from './Toast';
import './style/ToastWrapper.css';

const useTimer = (interval) => {
    const [tick, setTick] = useState(0);
  
    useEffect(() => {
      const timer = setInterval(() => {
        setTick(prev => prev + 1);
      }, interval);
  
      return () => clearInterval(timer); // Cleanup on component unmount
    }, [interval]);
  
    return tick;
}

const ToastWrapper = () => {
    const tick = useTimer(1000);
    const keystoneCalls = useSelector((state) => state?.keystone?.calls || []);
    const keystoneInventoryCalls = useSelector((state) => state?.keystoneInventory?.calls || []);
    const keystoneInterfaceCalls = useSelector((state) => state?.keystoneInterface?.calls || []);

    // Disable lint because tick controls the interval of the timer and clears the calls array every second.
    const calls = useMemo(() => [...keystoneInterfaceCalls, ...keystoneInventoryCalls, ...keystoneCalls].filter(item => (new Date().getTime() - item.time) < 3000), 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [keystoneCalls, keystoneInterfaceCalls, keystoneInventoryCalls, tick]);

    return (
        <div id="keystone-user-toasts-wrapper">
            <div className="product-vertical-container flex-1 width-100 v-gap-s pad-l align-center">
            {calls.filter(item => (new Date().getTime() - item.time) < 3000 && item.user === true).map((call, index) => (
                    <Toast key={index} index={index} type="user" message={call.message ? call.message : (`${call.type} ${call.action} ${call.status}`)} state={
                        call.status === "rejected" ? "negative"
                        : call.status === "fulfilled" ? "positive"
                        : "neutral"
                    } />
                ))}
            </div>
        </div>
    );
};

export default React.memo(ToastWrapper);