import Button from './Button';
import './style/InviteModal.css';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { inviteMember, setShowModal } from '../../redux/keystoneInterfaceSlice';
import { useSelector } from 'react-redux';
import Label from './Label';
import Input from './Input';
import Select from './Select';

const InviteModal = () => {
    const dispatch = useDispatch();
    const keystoneInterfaceCalls = useSelector((state) => state?.keystoneInterface?.calls);
    const keystoneInterfaceAccessToken = useSelector((state) => state?.keystoneInterface?.accessToken);
    
    const [inviteInputValue, setInviteInputValue] = useState("");
    const [inviteRole, setInviteRole] = useState("Viewer");

    const handleInviteInputChange = (e) => {
        e.preventDefault();
        setInviteInputValue(e.target.value);
    };

    const handleInviteMember = () => {
        dispatch(inviteMember({ accessToken: keystoneInterfaceAccessToken, email: inviteInputValue, role: inviteRole.toUpperCase() }));
    };

    useEffect(() => {
        if (keystoneInterfaceCalls?.some(item =>
            item.type === "team" &&
            item.action === "invite member" &&
            item.status === "rejected"
        )) {
            // TODO: error text and state
        }   

        if (keystoneInterfaceCalls?.some(item =>
            item.type === "team" &&
            item.action === "invite member" &&
            item.status === "fulfilled"
        )) {
            dispatch(setShowModal(false));
        }
    }, [dispatch, keystoneInterfaceCalls]);

    return (
        <div className="keystone-invite-modal modal-wrapper">
            <div className="modal-header flex-1 pad-s pad-h-l space-between align-center">
                <Label content="Invite Member" size="m" bold="true" displayFont={true} />
                <Button label="Close" onClick={() => dispatch(setShowModal(false))} />
            </div>
            <div className="pad-l no-pad-bot">
                <div className="product-horizontal-container mobile-vertical h-gap-m v-gap-m flex-1 justify-end">
                    <div className="product-vertical-container v-gap-xs flex-1">
                        <Label content="Email" size="s" bold="true" displayFont={true} />
                        <Input placeholder="name@site.com" type="email" onWhite={false} onChange={(e) => handleInviteInputChange(e)} large={true} />
                    </div>
                    <div className="product-vertical-container v-gap-xs flex-1">
                        <Label content="Role" size="s" bold="true" displayFont={true} />
                        <Select options={["Admin", "Viewer"]} value={inviteRole} onWhite={false} onChange={(e) => setInviteRole(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="modal-footer flex-1 justify-end pad-l h-gap-l">
                <div className="product-vertical-container">
                    <div className="product-horizontal-container h-gap-s">
                        <Button brand={true} label="Send Invite" large="true" onClick={handleInviteMember} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(InviteModal);