import './style/HeaderVisual.css';
import React, { useState, useCallback } from 'react';
import info from '../../media/info32@3x.png';
import rightArrowThick from '../../media/right-arrow-thick@3x.png';
import Icon from './Icon';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setHeaderMappings } from '../../redux/keystoneInterfaceSlice';
import Label from './Label';


const HeaderVisual = ({ index, label, tooltip, fancy, value, status, headerData, originalMappedValue, horizontal = false, icon = info, displayStyle = true }) => {
    const dispatch = useDispatch();
    const keystoneInventoryHeaderMappings = useSelector((state) => state?.keystoneInterface?.header_mappings);

    const [showTooltip, setShowTooltip] = useState(false);
    const [selectedHeaderIndex, setSelectedHeaderIndex] = useState(headerData?.indexOf(value));
    // eslint-disable-next-line eqeqeq -- This is a comparison of two values that may be null / undefined so we want fuzzy comparison here.
    const selectedHeaderMatchesAutoMapping = value == originalMappedValue;

    const handleHover = useCallback(() => {
        setShowTooltip(true);
    }, [setShowTooltip]);

    const handleLeave = useCallback(() => {
        setShowTooltip(false);
    }, [setShowTooltip]);

    const handleSelectChange = (e) => {
        if (!isNaN(Number(e.target.value))) {
            console.log(e.target.value);
            const updated = headerData[e.target.value] || null;
            const currentLabel = label;
            console.log(updated);
            const newHeaderMappings = {
                ...keystoneInventoryHeaderMappings,
                [currentLabel]: updated
            }
            dispatch(setHeaderMappings(newHeaderMappings));
        }

        setSelectedHeaderIndex(e.target.value === '' ? '' : Number(e.target.value));
    }

    return (
        <div key={index} className={(horizontal ? "product-horizontal-container flex-1 " : "product-vertical-container ") + (!selectedHeaderMatchesAutoMapping ? "updated header-visual" : (status ? status + " header-visual" : "header-visual"))}>
            {fancy && <div className="header-visual-vertical"></div>}
            <div className={"product-horizontal-container " + horizontal && "flex-1"}>
                {fancy && <div className="header-visual-horizontal"></div>}
                <div className={`keystone-header-visual ${!displayStyle ? "regular" : ""}`}>
                    <div className={"product-vertical-container v-gap-xs " + horizontal && "flex-1"}>
                        <div className="product-horizontal-container space-between h-gap-s align-center" onMouseEnter={!displayStyle ? handleHover : null} onMouseLeave={!displayStyle ? handleLeave : null}>
                            {label && <Label content={label} negative={status === "negative"} positive={status === "positive"} size="s" mono={true} uppercase={true} secondary={true} bold={true} />}
                            <Icon icon={icon} size="10" color={status === "negative" ? "#ba0e0e" : status === "positive" ? "#0e9c0e" : "#b9bfd6"} handleMouseEnter={displayStyle ? handleHover : null} handleMouseLeave={displayStyle ? handleLeave : null} />
                        </div>
                    </div>
                    {showTooltip && <div className="header-visual-tooltip"><Label content={tooltip} mono={true} size="s" /></div>}
                </div>
                {fancy && <div className="header-visual-horizontal"></div>}
            </div>
            {status && headerData && <div className={(horizontal ? "product-horizontal-container" : "product-vertical-container") + " align-center header-visual-arrow"}>
                <div className={(horizontal ? "horizontal" : "vertical") + "-divider"}></div>
                <div className="image-wrapper">
                    <img alt="" className="img-12" src={rightArrowThick}></img>
                </div>
                <div className={(horizontal ? "horizontal" : "vertical") + "-divider"}></div>
            </div>}
            {status && headerData && (
                <div className="header-visual-select-wrapper align-center flex-1">
                    <select className={`header-visual-select ${selectedHeaderIndex === -1 ? 'none-selected' : ''}`} value={selectedHeaderIndex} onChange={handleSelectChange}>
                        <option key={'-1'} value={''}>Select Header</option>
                        {Object.keys(headerData).map((key, index) => (
                            <option key={index} value={key}>{headerData[key] && headerData[key] !== "" ? headerData[key] : "Blank"}</option>
                        ))}
                    </select>
                </div>
            )}
            {fancy && <div className="header-visual-vertical"></div>}
        </div>
    );
};

export default React.memo(HeaderVisual);