import './style/MarketVisuals.css';
import React from 'react';
import ScoreBar from './ScoreBar';

const MarketVisuals = ({ stockStatus, stockRatio, priceStatus, priceRatio }) => {
    return (
        <div className="product-vertical-container no-pad-top v-gap-s">
            {stockStatus !== "Not Found" && <ScoreBar score={stockRatio} label={stockStatus} minLabel="" maxLabel="" />}
            {priceStatus !== "Not Found" && <ScoreBar score={priceRatio} label={priceStatus} minLabel="" maxLabel="" />}
        </div>
    );
};

export default React.memo(MarketVisuals);