import './style/Icon.css';
import React, { useEffect, useState } from 'react';

// Create a cache for storing generated icons
const iconCache = {};

const Icon = ({ children, icon, color = 'black', size = '24', z = null, invert, handleMouseEnter = null, handleMouseLeave = null, flex1 = true }) => {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    // Create a unique key based on the icon source and color
    const cacheKey = `${icon}-${color}-${invert}`;
    if (iconCache[cacheKey]) {
      // Use the cached Data URL if available
      setSrc(iconCache[cacheKey]);
      return;
    }

    // Create a new image
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Enable CORS if needed
    img.src = icon;

    img.onload = () => {
      // Create an off-screen canvas
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');

      // Draw the icon onto the canvas
      ctx.drawImage(img, 0, 0);

      // Apply color overlay while preserving alpha channels
      ctx.globalCompositeOperation = 'source-in';
      ctx.fillStyle = invert ? 'white' : color;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Convert the canvas to a Data URL
      const dataURL = canvas.toDataURL();

      // Cache the generated Data URL
      iconCache[cacheKey] = dataURL;

      // Update the state with the new src
      setSrc(dataURL);
    };

    img.onerror = () => {
      // Handle image load errors
      console.error(`Failed to load icon: ${icon}`);
    };
  }, [icon, color, invert]);

  // Render nothing until the image is ready
  if (!src) {
    return null;
  }

  return (
    <div className={`relative flex-1 ${invert ? 'invert-icon': ''}`} >
      <img
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        src={src}
        alt=""
        width={size}
        height={size}
        style={{ zIndex: z, minWidth: size, minHeight: size }}
      />
      { children }
    </div>
  );
};

export default React.memo(Icon);