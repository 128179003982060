import React, { } from 'react';

import Pie from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { LinearGradient } from '@visx/gradient';
import { animated, useTransition, to } from '@react-spring/web';
import SimpleLoader from './SimpleLoader';
import { PatternLines } from '@visx/pattern';
const defaultMargin = { top: 32, right: 32, bottom: 32, left: 32 };

const PieChart = ({ width, height, data, loading, showPercent = true }) => {
    // const [selectedSlice, setSelectedSlice] = useState(null);
    const innerWidth = width - defaultMargin.left - defaultMargin.right;
    const innerHeight = height - defaultMargin.top - defaultMargin.bottom;
    const radius = Math.min(innerWidth, innerHeight) / 2;
    const centerY = innerHeight / 2;
    const centerX = innerWidth / 2;
    const donutThickness = 30;

    const colorScale = scaleOrdinal({
        domain: data.map(d => d.label),
        range: [
            '#372d5b',
            '#372d5b50'
        ],
    });

    return (
        <div className="relative" style={{width: width, height: height, borderRadius: "12px"}}>
            <svg width={width} height={height} style={{position: "absolute", borderRadius: "12px", overflow: "visible"}}>
                {/* <GradientPinkBlue  /> */}
                <LinearGradient id="visx-pie-gradient" from="#351CAB" to="#621A61" rotate="-45" />
                {/* <LinearGradient id="visx-pie-gradient" from="#f6f8fb" to="#b9bfd6" rotate="-45" /> */}
                <PatternLines
                    id="lines"
                    height={5}
                    width={5}
                    stroke={'#E6E8F0'}
                    strokeWidth={1}
                    orientation={['diagonal']}
                />
                {/* <rect width={width} height={height} fill="url('#visx-pie-gradient')" /> */}
                <rect
                    width={width}
                    height={height}
                    rx={12} // border radius
                    ry={12} // border radius
                    fill="url('#lines')" />

                <Group top={height / 2} left={width / 2}>

                <Pie
                    // data={selectedSlice ? data.filter(d => d.label === selectedSlice) : data}
                    data={data}
                    pieValue={d => d.value}
                    outerRadius={radius}
                    innerRadius={radius - donutThickness}
                    padAngle={0.04}
                    cornerRadius={3}
                    patternTransform={pattern => `translate(${centerX}, ${centerY})`}
                >
                    {(pie) => (
                    <AnimatedPie
                        {...pie}
                        getKey={arc => arc.data.label}
                        // onClickDatum={({ data }) => setSelectedSlice(selectedSlice === data.label ? null : data.label)}
                        onClickDatum={() => {}}
                        getColor={arc => colorScale(arc.data.label)}
                        showPercent={showPercent}
                    />
                    )}
                </Pie>
                </Group>
            </svg>
            <SimpleLoader loading={loading} />
        </div>
    );
};

function AnimatedPie({ arcs, path, getKey, getColor, onClickDatum, showPercent }) {
    const transitions = useTransition(arcs, {
        from: { startAngle: 0, endAngle: 0, opacity: 0 },
        enter: ({ startAngle, endAngle }) => ({ startAngle, endAngle, opacity: 1 }),
        update: ({ startAngle, endAngle }) => ({ startAngle, endAngle, opacity: 1 }),
        leave: { startAngle: 0, endAngle: 0, opacity: 0 },
        keys: getKey
    });
  
    return transitions((props, arc) => {
        const [centroidX, centroidY] = path.centroid(arc);
        const label = `${arc.data.label}`;
        const value = `${(arc.data.value * 100).toFixed(0)}%`;

        // Estimate the width based on text length
        const textWidth = Math.max(label.length, value.length) * 5.4 + 12; // Basic approximation
        const textHeight = showPercent ? 28 : 18; // Enough for two lines with some padding and margin

        return (
            <g key={getKey(arc)} onClick={() => onClickDatum(arc)}>
                <animated.path
                    d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
                        path({ ...arc, startAngle, endAngle })
                    )}
                    fill={getColor(arc)}
                />
                <animated.g style={{ opacity: props.opacity }}>
                    <rect
                        x={centroidX - textWidth / 2}
                        y={centroidY - textHeight / 2}
                        width={textWidth}
                        height={textHeight}
                        rx={5} // border radius
                        ry={5} // border radius
                        fill="#fff" // transparent black fill
                        stroke="#372d5b"
                    />
                    <text
                        x={centroidX}
                        y={showPercent ? (centroidY - 2) : (centroidY + 3)}
                        fontSize={9}
                        fontWeight={700}
                        fontFamily="SF Mono"
                        fill="#000"
                        textAnchor="middle"
                    >
                        {label}
                    </text>
                    {showPercent && (
                        <text
                            x={centroidX}
                            y={centroidY + 9}
                            fontSize={10}
                            fontWeight={700}
                            fontFamily="SF Mono"
                            fill="#000"
                            textAnchor="middle"
                        >
                            {value}
                        </text>
                    )}
                </animated.g>
            </g>
        );
    });
}

export default React.memo(PieChart);