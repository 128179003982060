import React from 'react';
import './App.css';
import { useAuth0 } from "@auth0/auth0-react";

import Login from './page/Login';
import Keystone from './page/Keystone';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

function App() {
  const keystoneTemporaryAccessToken = useSelector((state) => state?.keystone?.temporaryAccessToken);
  const { isAuthenticated, isLoading } = useAuth0();
  
  return (
    <Router>
      <Routes>
        <Route key={"/:page"} path={"/:page"} element={<Keystone />} />
        <Route key={"/:page/:tabName"} path={"/:page/:tabName"} element={<Keystone />} />
        <Route path="/" element={<>
          { (isAuthenticated || keystoneTemporaryAccessToken) && <Keystone /> }
          { !isLoading && !isAuthenticated && !keystoneTemporaryAccessToken && <Login /> }
        </>} />
      </Routes>
    </Router>
  );
}

export default React.memo(App);