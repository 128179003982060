import './style/Keystone.css';
import React, { useEffect, useState } from 'react';
import { useEvent as useEffectEvent } from '@reactuses/core';
import Menu from '../component/keystone/Menu';
import Tray from '../component/keystone/Tray';
import Content from '../component/keystone/Content';
import Modal from '../component/keystone/Modal';
import Loader from '../component/keystone/Loader';
import ToastWrapper from '../component/keystone/ToastsWrapper';
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setView, setNotifications, fetchMagicLinkAccessToken, fetchUser, setMagicLinkCode, setAuthRedirectPage } from '../redux/keystoneSlice';
import { selectAllInventoryItemsWithData } from '../redux/keystoneInventorySlice';
import { setIsLoggedIn, setShowModal, setModalType, fetchCompany, setAccessToken, setInviteCode, setPayoutsSetup, verifyInviteCode, fetchInventory, fetchShipments, fetchTeam, fetchPayouts, fetchOffers, fetchBankAccounts, setTemporaryProductFound } from '../redux/keystoneInterfaceSlice';
import { resetAppState } from '../redux/appReducer';
import SimpleLoader from '../component/keystone/SimpleLoader';
import Plaid from '../component/keystone/Plaid';

const loadingTextOptions = [
    "Looking up the part...",
    "Gathering price data...",
    "Checking stock levels...",
    "Assembling product page..."
];

const Keystone = () => {
    const dispatch = useDispatch();

    // state.keystone
    const keystoneMagicLinkCode = useSelector((state) => state?.keystone?.magicLinkCode);
    const keystoneAuthRedirectPage = useSelector((state) => state?.keystone?.auth?.redirectPage);
    const keystoneNotifications = useSelector((state) => state?.keystone?.notifications);
    const keystoneTemporaryAccessToken = useSelector((state) => state?.keystone?.temporaryAccessToken);
    const keystoneErrorFetchingAccessToken = useSelector((state) => state?.keystone?.errorFetchingAccessToken);

    // state.keystoneInventory
    const keystoneInventoryBankAccounts = useSelector((state) => state?.keystoneInventory?.bank_accounts);
    const keystoneInventoryAll = useSelector(selectAllInventoryItemsWithData);

    // state.keystoneInterface
    const keystoneInterfaceSearchingMpn = useSelector((state) => state?.keystoneInterface?.searchingMpn);
    const keystoneInterfaceHasFetchedInventory = useSelector((state) => state?.keystoneInterface?.hasFetchedInventory);
    const keystoneInterfaceOperationId = useSelector((state) => state?.keystoneInterface?.operation_id);
    const keystoneInterfaceTemporaryProductFound = useSelector((state) => state?.keystoneInterface?.temporaryProductFound);
    const keystoneInterfaceErrorSearchingMpn = useSelector((state) => state?.keystoneInterface?.errorSearchingMpn);
    const keystoneInterfaceErrorFetchingInventory = useSelector((state) => state?.keystoneInterface?.errorFetchingInventory);
    const keystoneInterfaceInviteCode = useSelector((state) => state?.keystoneInterface?.inviteCode);
    const keystoneInterfaceAccessToken = useSelector((state) => state?.keystoneInterface?.accessToken);
    const keystoneInterfaceInviteVerified = useSelector((state) => state?.keystoneInterface?.inviteVerified);
    const keystoneInterfaceIsLoggedIn = useSelector((state) => state?.keystoneInterface?.isLoggedIn);
    const keystoneInterfaceHasFetchedUser = useSelector((state) => state?.keystoneInterface?.hasFetchedUser);
    const keystoneInterfaceErrorFetchingUser = useSelector((state) => state?.keystoneInterface?.errorFetchingUser);
    const keystoneInterfaceTosAccepted = useSelector((state) => state?.keystoneInterface?.tosAccepted);
    const keystoneInterfaceErrorVerifyingInviteCode = useSelector((state) => state?.keystoneInterface?.errorVerifyingInviteCode);
    const keystoneInterfaceCompanyHasLinkedBankAccounts = useSelector((state) => state?.keystoneInterface?.company?.has_linked_bank_accounts);
    const keystoneInterfaceCompany = useSelector((state) => state?.keystoneInterface?.company);
    const keystoneInterfaceUploadingInventory = useSelector((state) => state?.keystoneInterface?.uploadingInventory);
    const keystoneUser = useSelector((state) => state?.keystone?.user);

    const navigate = useNavigate();
    const { search, pathname } = useLocation();
    const searchParams = Object.fromEntries(new URLSearchParams(search));
    const { page } = useParams();
    const { isAuthenticated, user, logout, getAccessTokenSilently, loginWithRedirect, isLoading, error } = useAuth0();
    const loader = true;

    const [ showLoader, setShowLoader ] = useState(loader);
    const [menuOpen, setMenuOpen] = useState(true);

    const showLoadingText = keystoneInterfaceSearchingMpn === true;

    
    // Custom login function
    const handleGoogleAccountCreate = async () => {
        dispatch(setAuthRedirectPage(page || 'inventory'));
        return await loginWithRedirect({
            authorizationParams: {
                connection: 'google-oauth2',
                magic_link_code: keystoneMagicLinkCode,
            }
        });
    };

    const handleGoogleAccountCreateFromInvite = async () => {
        dispatch(setAuthRedirectPage('inventory')); // go to inventory after invite
        return await loginWithRedirect({
            authorizationParams: {
                connection: 'google-oauth2',
                invite_code: keystoneInterfaceInviteCode,
            }
        });
    };

    const handleCredentialAccountCreate = async (email) => {
        dispatch(setAuthRedirectPage(page || 'inventory'));
        return await loginWithRedirect({
            authorizationParams: {
                screen_hint: 'signup',
                connection: 'email',
                login_hint: email,
                magic_link_code: keystoneMagicLinkCode,
            },
        });
    };

    const handleCredentialAccountCreateFromInvite = async (email) => {
        dispatch(setAuthRedirectPage('inventory')); // go to inventory after invite
        return await loginWithRedirect({
            authorizationParams: {
                screen_hint: 'signup',
                connection: 'email',
                login_hint: email,
                invite_code: keystoneInterfaceInviteCode,
            },
        });
    };

    const getAccessToken = useEffectEvent(async () => {
        try {
            const local = window.location.origin.includes('local') ? true : false;

            const opts = {
                authorizationParams: {
                    audience: local ? 'https://dev-s6tdx5li201sdtoo.us.auth0.com/api/v2/' : 'https://trymaterialize.us.auth0.com/api/v2/',
                    scope: "openid profile email offline_access"
                },
            };
        
            let accessToken = await getAccessTokenSilently(opts);
            dispatch(setAccessToken(accessToken));
            if (keystoneAuthRedirectPage) {
                const redirectPage = keystoneAuthRedirectPage;
                dispatch(setAuthRedirectPage(null));
                navigate(`/${redirectPage}`);
            }
        } catch (error) {
            console.log(error);
        }
    });

    const handleLogout = useEffectEvent(() => {
        dispatch(resetAppState());
        setShowLoader(true);
        logout();
    });

    const fetchBankAccountData = useEffectEvent(() => {
        dispatch(fetchBankAccounts({ accessToken: keystoneInterfaceAccessToken }));
    });
    
    const navigateToSelectedId = useEffectEvent((id) => {
        navigate(`${pathname}?id=${id}`);
    });
    
    const fetchUserAndCompany = useEffectEvent(() => {
        dispatch(fetchCompany({ accessToken: keystoneInterfaceAccessToken }));
        dispatch(fetchUser({ accessToken: keystoneInterfaceAccessToken }));
    });
    
    if (keystoneNotifications?.find(notification => notification.page === page)) {
        const updatedNotifications = keystoneNotifications?.filter(notification => notification.page !== page);
        dispatch(setNotifications(updatedNotifications));
    }

    useEffect(() => {
        if (page === 'magiclink' && isAuthenticated) {
            console.log("Ignoring magic link, user is already logged in.");
            navigate('/inventory');
        } else if (page === 'magiclink' && searchParams.mc && !keystoneInterfaceAccessToken) {
            dispatch(resetAppState());
            dispatch(setMagicLinkCode(searchParams.mc));
            dispatch(fetchMagicLinkAccessToken({ code: searchParams.mc }));
            setShowLoader(true);
        } else if (page === 'magiclink') {
            navigate('/inventory');
        }
    }, [page, isAuthenticated, searchParams.mc, keystoneInterfaceAccessToken, navigate, dispatch]);

    useEffect(() => {
        if (page === 'invite' && !isLoading && !user && !keystoneTemporaryAccessToken && searchParams.code) {
            dispatch(resetAppState());
            dispatch(setInviteCode(searchParams.code));
            dispatch(setView("invite"));
            setShowLoader(true);
            dispatch(setModalType("create-account-invite"));
            dispatch(setShowModal(true));
            dispatch(verifyInviteCode({ code: searchParams.code }));
        } else if (page === 'invite' && !isLoading) {
            console.log("Ignoring invite link as user already has account.");
            navigate('/inventory');
        }
    }, [page, isLoading, user, keystoneTemporaryAccessToken, searchParams.code, dispatch, navigate]);

    useEffect(() => {
        if (keystoneInterfaceInviteVerified) {
            setShowLoader(false);
        }
    }, [keystoneInterfaceInviteVerified]);
    
    useEffect(() => {
        if (keystoneInventoryBankAccounts?.length > 0) {
            dispatch(setPayoutsSetup(true));
        } else {
            dispatch(setPayoutsSetup(false));
        }
    }, [dispatch, keystoneInventoryBankAccounts?.length]);

    useEffect(() => {
        if (user) {
            dispatch(setIsLoggedIn(true));
            setShowLoader(false);
        }
    }, [dispatch, user]);
    
    useEffect(() => {
        if (keystoneInterfaceIsLoggedIn && keystoneInterfaceHasFetchedUser && !keystoneInterfaceErrorFetchingUser && !keystoneInterfaceTosAccepted) {
            dispatch(setModalType("tos"));
            dispatch(setShowModal(true));
        } else if (keystoneInterfaceTosAccepted) {
            dispatch(setShowModal(false));
        }
    }, [keystoneInterfaceIsLoggedIn, keystoneInterfaceHasFetchedUser, keystoneInterfaceErrorFetchingUser, keystoneInterfaceTosAccepted, dispatch]);

    useEffect(() => {
        if (keystoneInterfaceAccessToken && keystoneInterfaceHasFetchedInventory && keystoneInventoryAll?.length === 0 && keystoneMagicLinkCode) {    
            dispatch(setShowModal(true));
            dispatch(setModalType("upload-flow"));
        }
    }, [keystoneInterfaceAccessToken, keystoneInterfaceHasFetchedInventory, keystoneInventoryAll?.length, keystoneMagicLinkCode, dispatch]);

    useEffect(() => {
        if (error) {
            console.error(error);
        }
        if (!isLoading && isAuthenticated) {
            getAccessToken();
        }
    }, [isAuthenticated, isLoading, error]);

    useEffect(() => {
        if (keystoneInterfaceErrorVerifyingInviteCode) {
            handleLogout();
        }
    }, [keystoneInterfaceErrorVerifyingInviteCode]);

    useEffect(() => {
        if (!isLoading && page && page !== 'invite' && page !== 'magiclink' && !isAuthenticated && !keystoneTemporaryAccessToken && !keystoneInterfaceAccessToken && !keystoneMagicLinkCode) {
            // Authenticated pages require an access token. Log them out if not logged in.
            handleLogout();
        }
    }, [page, keystoneTemporaryAccessToken, keystoneInterfaceAccessToken, keystoneMagicLinkCode, isAuthenticated, isLoading]);

    useEffect(() => {
        if (!page && (keystoneInterfaceIsLoggedIn || (keystoneTemporaryAccessToken && !isLoading))) {
            navigate('/inventory');
        } else if (!page && isLoading && searchParams.code) {
            setShowLoader(true);
        }
    }, [page, keystoneInterfaceIsLoggedIn, keystoneTemporaryAccessToken, navigate, isLoading, searchParams.code]);

    useEffect(() => {
        if (keystoneInterfaceCompanyHasLinkedBankAccounts) {
            fetchBankAccountData();
        }
    }, [keystoneInterfaceCompanyHasLinkedBankAccounts]);

    
    useEffect(() => {
        if (keystoneInterfaceOperationId) {
            setShowLoader(false);
        }
    }, [dispatch, keystoneInterfaceOperationId]);

    useEffect(() => {
        if (keystoneInterfaceSearchingMpn) {
            setShowLoader(true);
        }
    }, [keystoneInterfaceSearchingMpn]);
    

    useEffect(() => {
        if (keystoneInterfaceTemporaryProductFound && keystoneInventoryAll?.length > 0) {
            dispatch(setShowModal(false));
            setShowLoader(false);
            navigateToSelectedId(keystoneInventoryAll[0]?.id);
            dispatch(setTemporaryProductFound(false));
        }
    }, [keystoneInterfaceTemporaryProductFound, keystoneInventoryAll, dispatch]);

    useEffect(() => {
        if (keystoneInterfaceErrorSearchingMpn) {
            setShowLoader(false);
        }
    }, [keystoneInterfaceErrorSearchingMpn]);

    useEffect(() => {
        if (page && keystoneInterfaceAccessToken) {
            if (page === "shipments") {
                dispatch(fetchShipments({ accessToken: keystoneInterfaceAccessToken }));
            } else if (page === "payouts") {
                dispatch(fetchPayouts({ accessToken: keystoneInterfaceAccessToken }));
            } else if (page === "team") {
                dispatch(fetchTeam({ accessToken: keystoneInterfaceAccessToken }));
            } else if (page === "offers") {
                dispatch(fetchOffers({ accessToken: keystoneInterfaceAccessToken }));
            }
        }
    }, [page, keystoneInterfaceAccessToken, dispatch]);

    // useEffect(() => {
    //     if (keystoneInterfaceUploadingInventory === true) {
    //         setShowLoader(true);
    //     } else if (keystoneInterfaceUploadingInventory === false) {
    //         setShowLoader(false);
    //     }
    // }, [keystoneInterfaceUploadingInventory]);

    useEffect(() => {
        if (keystoneInterfaceErrorFetchingInventory === 'Unauthorized') {
            handleLogout();
        }
    }, [keystoneInterfaceErrorFetchingInventory]);

    useEffect(() => {
        if (keystoneErrorFetchingAccessToken && !keystoneInterfaceAccessToken && !user && !isLoading) {
            handleLogout();
        }
    }, [keystoneErrorFetchingAccessToken, keystoneInterfaceAccessToken, user, isLoading]);

    useEffect(() => {
        if (keystoneInterfaceHasFetchedInventory && !keystoneInterfaceAccessToken && keystoneTemporaryAccessToken) {
            dispatch(setAccessToken(keystoneTemporaryAccessToken));
            setShowLoader(false);
        }
    }, [keystoneInterfaceHasFetchedInventory, keystoneInterfaceAccessToken, keystoneTemporaryAccessToken, dispatch]);

    useEffect(() => {
        if (!keystoneInterfaceAccessToken) {
            if (keystoneTemporaryAccessToken) {
                dispatch(fetchInventory({ accessToken: keystoneTemporaryAccessToken }));
            }
        }
    }, [keystoneInterfaceAccessToken, keystoneTemporaryAccessToken, dispatch]);

    useEffect(() => {
        if ((!keystoneInterfaceCompany || !keystoneUser) && keystoneInterfaceAccessToken) {
            fetchUserAndCompany();
        }
    }, [keystoneInterfaceAccessToken, keystoneInterfaceCompany, keystoneUser]);

    // TODO: Address polling
    useEffect(() => {
        let intervalId;
      
        if (keystoneInterfaceAccessToken && page === "inventory") {
            if (keystoneInventoryAll?.length === 0) {
                dispatch(fetchInventory({ accessToken: keystoneInterfaceAccessToken }));
            }
            
            intervalId = setInterval(() => {
                if (keystoneInterfaceAccessToken && page === "inventory") {
                    dispatch(fetchInventory({ accessToken: keystoneInterfaceAccessToken }));
                }
            }, 60000);
        }
      
        return () => {
          if (intervalId) {
            clearInterval(intervalId);
          }
        };
    }, [dispatch, keystoneInterfaceAccessToken, page, keystoneInventoryAll?.length]);
    

    return (
        <div id="keystone">
            <script src="https://cdn.plaid.com/link/v2/stable/link-initialize.js"></script>
            <div id="content-wrapper" className={menuOpen && 'menu-open'}>
                <Menu
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    className={menuOpen ? 'open' : 'closed'}
                    handleLogout={() => handleLogout()}
                ></Menu>
                <Content></Content>
                <Menu
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    className={menuOpen ? 'open' : 'closed'}
                    handleLogout={() => handleLogout()}
                ></Menu>
            </div>
            <Tray></Tray>
            <Modal
                handleCredentialAccountCreate={handleCredentialAccountCreate}
                handleCredentialAccountCreateFromInvite={handleCredentialAccountCreateFromInvite}
                handleGoogleAccountCreate={handleGoogleAccountCreate}
                handleGoogleAccountCreateFromInvite={handleGoogleAccountCreateFromInvite} />
            <Loader loading={showLoader} showLoadingText={showLoadingText} loadingTextOptions={loadingTextOptions} />
            <Plaid />
            <ToastWrapper />
        </div>
    );
};

export default React.memo(Keystone);
