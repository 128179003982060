import Label from './Label';
import './style/ChipRounded.css';
import React from 'react';

const ChipRounded = ({ index, label, secondary = true, small, positive = false, negative = false}) => {
    return (
        <div key={index} className={`keystone-chip-rounded ${secondary ? "secondary" : ""} ${positive ? "positive" : ""} ${negative ? "negative" : ""} ${small ? "small" : ""}`}>
            <Label content={label} size={small ? "xs" : "s"} bold={true} mono={true} uppercase={true} />
        </div>
    );
};

export default React.memo(ChipRounded);