import './style/ProductDetails.css';
import React, { useState, useEffect, useMemo } from 'react';
import ChipRounded from './ChipRounded';
import noStock from '../../media/no-stock@3x.png';
import underStocked from '../../media/under-stocked@3x.png';
import averageStock from '../../media/average-stock@3x.png';
import overStocked from '../../media/over-stocked@3x.png';
import overCosted from '../../media/over-costed@3x.png';
import highlyCompetitive from '../../media/highly-competitive@3x.png';
import competitive from '../../media/competitive@3x.png';
import moderatelyCompetitive from '../../media/moderately-competitive@3x.png';
import lessCompetitive from '../../media/less-competitive@3x.png';
import shortLeadTimes from '../../media/short-lead-times@3x.png';
import longLeadTimes from '../../media/long-lead-times@3x.png';
import notManufactured from '../../media/not-manufactured@3x.png';
import stillManufactured from '../../media/still-manufactured@3x.png';
import unknown from '../../media/unknown@3x.png';
import allAspects from '../../media/all-aspects@3x.png';
import hourglassEmpty from '../../media/hourglass-empty@3x.png';
import MarketVisuals from './MarketVisuals';
import { formatPercentWithCommas, formatToCurrency, formatToCurrencyExpanded } from '../../util/Calculate';
import { useSelector } from 'react-redux';
import Input from './Input';
import Label from './Label';
import TrayHeader from './TrayHeader';
import InfoBlock from './InfoBlock';
import ScoreBar from './ScoreBar';
import Divider from './Divider';
import Row from './Row';
import { useLocation } from 'react-router-dom';
import { makeSelectInventoryItemById } from '../../redux/keystoneInventorySlice';

const ProductDetails = ({ productData }) => {
    const keystoneInterfaceIsLoggedIn = useSelector((state) => state?.keystoneInterface?.isLoggedIn);
    const keystoneInterfaceOffersAccepted = useSelector((state) => state?.keystoneInterface?.offersAccepted);
    const keystoneInventoryOffers = useSelector((state) => state?.keystoneInventory?.offers);

    const [productQuantity, setProductQuantity] = useState(productData?.quantity || 1);
    const [productUnitCost, setProductUnitCost] = useState(productData?.unit_value || 0.01);

    const [stockIcon, setStockIcon] = useState(averageStock);
    const [stockStatus, setStockStatus] = useState("Average Stock");
    const [stockRatio, setStockRatio] = useState(0);
    const [priceIcon, setPriceIcon] = useState(averageStock);
    const [priceStatus, setPriceStatus] = useState("Moderately Competitive");
    const [priceRatio, setPriceRatio] = useState(0);
    const [, setProductionIcon] = useState(stillManufactured);
    const [, setProductionStatus] = useState("Still Manufactured");
    const [endingProduction, setEndingProduction] = useState(false);
    const [userOverriddenQuantity, setUserOverriddenQuantity] = useState(null);
    const [userOverriddenPrice, setUserOverriddenPrice] = useState(null);

    const { search } = useLocation();
    const selectedObjectId = new URLSearchParams(search).get('id') || null;
    const selectInventoryItemById = useMemo(() => makeSelectInventoryItemById(), []);
    const selectedObject = useSelector(state => selectInventoryItemById(state, selectedObjectId));

    useEffect(() => {
        setUserOverriddenPrice(null);
        setUserOverriddenQuantity(null);
    }, [selectedObject]);

    useEffect(() => {
        if (productData) {
            setProductQuantity(productData?.quantity || 1);
            setProductUnitCost(productData?.unit_value || 0.01);
        }
    }, [productData]);

    useEffect(() => {
        if (productData) {
            /* STOCK DETAILS */
            const stockLevel = productData?.associated_part?.stock_level;
            setStockIcon(
                stockLevel === "NONE" ? noStock :
                stockLevel === "OVER"  ? overStocked :
                stockLevel === "UNDER" ? underStocked :
                stockLevel === "AVERAGE" ? averageStock :
                unknown);

            setStockStatus(
                stockLevel === "NONE" ? "No Stock" :
                stockLevel === "OVER"  ? "Overstocked" :
                stockLevel === "UNDER" ? "Low Stock" :
                stockLevel === "AVERAGE" ? "Average Stock" :
                "Not Found");

            const averageQuantity = Math.max(0, productData?.associated_part?.avg_total_avail);
            const actualQuantity = Math.max(0, productData?.associated_part?.total_avail);
            let ratio = averageQuantity / actualQuantity;

            if (averageQuantity === 0 && actualQuantity === 0) {
                ratio = 1;
            } else if (actualQuantity === 0) {
                ratio = 1;
            } else {
                ratio = 1 - (1 / (1 + ratio));
            }

            setStockRatio(ratio);

            /* PRICE DETAILS */
            const costAboveDistribution = productData?.unit_value > productData?.associated_part?.estimated_distributor_cost;
            const costBelowDistribution = productData?.unit_value < productData?.associated_part?.estimated_distributor_cost;

            const costBelowSpreadOneThird = productData?.unit_value < productData?.computed?.spread_one_third;
            const costAboveSpreadTwoThird = productData?.unit_value > productData?.computed?.spread_two_third;
            const costBetweenSpread = productData?.unit_value  >= productData?.computed?.spread_one_third && productData?.unit_value  <= productData?.computed?.spread_two_third;

            setPriceIcon(
                costAboveDistribution ? overCosted :
                costBelowDistribution ? highlyCompetitive : 
                costBelowSpreadOneThird ? competitive : 
                costAboveSpreadTwoThird ? lessCompetitive :
                costBetweenSpread ? moderatelyCompetitive :
                unknown
            );

            setPriceStatus(
                !productData?.associated_part?.estimated_distributor_cost ? "Not Found" :
                costAboveDistribution ? "Over Costed" :
                costBelowDistribution ? "Competitive Unit Value" : 
                costBelowSpreadOneThird ? "Competitive" : 
                costAboveSpreadTwoThird ? "Less Competitive" :
                costBetweenSpread ? "Moderately Competitive" :
                "Not Found"
            );

            let competitivenessScore = 0;

            if (productData?.unit_value <= 0 || productData?.associated_part?.estimated_distributor_cost <= 0) {
                competitivenessScore = 1;
            } else {
                // Calculate the ratio of estimated distributor cost to product unit cost
                const ratio = productData?.associated_part?.estimated_distributor_cost / productData?.unit_value;

                // Use a non-linear function to compute the competitiveness score
                competitivenessScore = 1 - (1 / (1 + ratio));

                // Ensure the competitiveness score is between 0 and 1
                competitivenessScore = Math.max(0, Math.min(1, competitivenessScore));
            }

            setPriceRatio(competitivenessScore);
            
            /* PRODUCTION DETAILS */
            const hasLongLeadTime = productData?.associated_part?.estimated_factory_lead_weeks && productData?.associated_part?.estimated_factory_lead_weeks > 4;
            const hasShortLeadTime = productData?.associated_part?.estimated_factory_lead_weeks && productData?.associated_part?.estimated_factory_lead_weeks <= 4;
            const isInProduction = productData?.associated_part?.lifecycle_status && productData?.associated_part?.lifecycle_status === "ACTIVE" ? true : productData?.associated_part?.lifecycle_status === "INACTIVE" ? false : null;
            
            // TODO: ADD TO ICON AND STATUS SET
            const isEndingProduction = productData?.associated_part?.lifecycle_status && (productData?.associated_part?.lifecycle_status === "EOL" || productData?.associated_part?.lifecycle_status === "NRND");

            setProductionIcon(
                isEndingProduction ? hourglassEmpty :
                hasLongLeadTime ? longLeadTimes :
                hasShortLeadTime ? shortLeadTimes :
                isInProduction ? stillManufactured :
                isInProduction === false ? notManufactured :
                unknown
            )

            setEndingProduction(isEndingProduction);

            setProductionStatus(
                isEndingProduction ? "Ending Production" :
                hasLongLeadTime ? "Long Lead Times" :
                hasShortLeadTime ? "Short Lead Times" :
                isInProduction ? "Still Manufactured" :
                isInProduction === false ? "Not Manufactured" :
                "Not Found"
            )
        }
    }, [productData, productUnitCost, productQuantity]);

    return (
        <div className="keystone-offer-details product-vertical-container flex-1 width-100">
            <TrayHeader
                type={productData?.manufacturer || productData?.associated_part?.manufacturer}
                label={productData?.mpn || productData?.associated_part?.mpn}
            />
            <div className="product-vertical-container flex-1 width-100 overflow-auto">
                <div className="product-vertical-container flex-1 width-100 pad-l v-gap-m overflow-auto">
                    <div id="product-example-header" className="product-vertical-container">
                        <div className="product-vertical-container h-gap-xl align-center">
                            <div className="product-vertical-container flex-1 width-100 v-gap-xl">
                                <div className="product-vertical-container flex-1 keystone-card width-100">
                                    <div className="product-vertical-container flex-1 width-100 keystone-card-footer v-gap-l">
                                        <div className="product-horizontal-container flex-1 width-100 space-between">
                                            <Label uppercase={true} content="Your Stock" size="s" bold={true} mono={true} />
                                            {/* <ChipRounded label="Your Stock" small={true} /> */}
                                        </div>
                                        <div className="product-horizontal-container h-gap-l flex-1 width-100">
                                            {keystoneInterfaceIsLoggedIn ? (
                                                <InfoBlock
                                                    primaryLabel="Quantity"
                                                    secondaryLabel={userOverriddenQuantity ?? productQuantity}
                                                    flex={true}
                                                />
                                            ) : (
                                                <div className="product-vertical-container flex-1 v-gap-xs">
                                                    <Label content="Quantity" size="s" bold={true} displayFont={true} />
                                                    <Input type="number" placeholder="Quantity" width100={true} value={userOverriddenQuantity ?? productQuantity} onChange={(e) => setUserOverriddenQuantity(e.target.value ? Number(e.target.value) : e.target.value)} />
                                                </div>
                                            )}
                                            {keystoneInterfaceIsLoggedIn ? (
                                                <InfoBlock
                                                    primaryLabel="Unit Value"
                                                    secondaryLabel={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(userOverriddenPrice ?? productUnitCost)}
                                                    flex={true}
                                                />
                                            ) : (
                                                <div className="product-vertical-container flex-1 v-gap-xs">
                                                    <Label content="Unit Value" size="s" bold={true} displayFont={true} />
                                                    <Input type="number" placeholder="Unit Value" width100={true} value={userOverriddenPrice ?? productUnitCost} onChange={(e) => setUserOverriddenPrice(e.target.value ? Number(e.target.value) : e.target.value)} />
                                                </div>
                                            )}
                                            {keystoneInterfaceIsLoggedIn ? (
                                                <InfoBlock
                                                    primaryLabel="Total Value"
                                                    secondaryLabel={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(((userOverriddenPrice ?? productUnitCost) * (userOverriddenQuantity ?? productQuantity)) || 0)}
                                                    flex={true} />
                                            ) : (
                                                <div className="product-vertical-container flex-1 v-gap-xs">
                                                    <Label content="Total Value" size="s" bold={true} displayFont={true} />
                                                    <Label content={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format((userOverriddenPrice ?? productUnitCost) * (userOverriddenQuantity ?? productQuantity))} size="m" line="xl" mono={true} />
                                                </div>
                                            )}
                                        </div>
                                        {keystoneInterfaceIsLoggedIn && <ScoreBar score={productData?.computed?.percentage_of_total_min_price} label={formatPercentWithCommas(productData?.computed?.percentage_of_total_min_price) + " Of Inventory Value"} minLabel="" maxLabel="" />}
                                    </div>
                                    <Divider vertical={false} />
                                    {(!keystoneInventoryOffers || !keystoneInventoryOffers?.some(offer => offer.offer_items?.some(item => item.inventory_item?.id === selectedObjectId))) ? (
                                        <div className="product-horizontal-container align-center space-between pad-l">
                                            <Label uppercase={true} content="Offers" size="s" bold={true} mono={true} />
                                            <Label content={keystoneInterfaceOffersAccepted ? "No Offers Received" : "Not Accepting Offers"} secondary={true} displayFont={true} mono={true} uppercase={true} size="s" />
                                        </div>
                                    ) : (
                                        <div className="product-vertical-container pad-l v-gap-s">
                                            <div className="product-horizontal-container align-center space-between">
                                                <Label uppercase={true} content="Offers" size="s" bold={true} mono={true} />
                                                <Label content={`Received ${keystoneInventoryOffers?.length} Offers`} secondary={true} displayFont={true} bold={true} size="s" positive={true} />
                                            </div>
                                            {keystoneInventoryOffers?.filter(offer => offer.offer_items?.filter(item => item.inventory_item?.id === selectedObjectId)).map((offer, index) => (
                                                <Row key={offer.id} index={offer.id} primaryLabel={`Offer #${offer.id} - ${offer.total_price}`} primaryMono={true} secondary={true} navigateOnClick={true} page="offers" tab={offer.status === "AWAITING_RESPONSE" ? "active" : offer.status === "ACCEPTED" ? "accepted" : offer.status === "COMPLETED" ? "completed" : "rejected"} />
                                            ))}
                                        </div>
                                    )}
                                </div>
                                {/* <div id="linked-part">
                                    <Icon icon={link} color="#000" size="32" />
                                </div> */}
                                <div className="product-vertical-container flex-1 keystone-card">
                                    <div className="product-vertical-container flex-1">
                                        <div className="product-vertical-container flex-1">

                                            <div className="product-vertical-container v-gap-s pad-l">
                                                <div className="product-horizontal-container space-between">
                                                    <Label uppercase={true} content="Market Data" size="s" bold={true} mono={true} />
                                                    {endingProduction && ( <ChipRounded label="Ending Production" positive={true} small={true} /> )}
                                                </div>
                                                {productData?.associated_part && (<div className="product-vertical-container v-gap-xxs">
                                                    <InfoBlock
                                                        primaryLabel={productData?.associated_part?.manufacturer}
                                                        secondaryLabel={productData?.associated_part?.mpn}
                                                        flex={true} />
                                                    <Label content={productData?.associated_part?.short_description} mono={true} secondary={true} size="s" />
                                                </div>)}
                                                {!productData?.associated_part && productData?.normalization_status !== 'NO_MATCH' && (<div className="product-vertical-container v-gap-xxs">
                                                    <Label content="We're working on matching this part to market data, check back soon." mono={true} secondary={true} size="s" />
                                                </div>)}
                                                {!productData?.associated_part && productData?.normalization_status === 'NO_MATCH' && (<div className="product-vertical-container v-gap-xxs">
                                                    <Label content="We weren't able to automatically identify this part." mono={true} secondary={true} size="s" />
                                                </div>)}
                                            </div>
                                            <div className="product-vertical-container flex-1 width-100 pad-l no-pad-top">
                                                <div className="product-horizontal-container h-gap-l">
                                                    <InfoBlock
                                                        primaryLabel="Quantity Available"
                                                        secondaryLabel={(productData?.associated_part?.total_avail && Math.max(0, productData?.associated_part?.total_avail)) || "-"}
                                                        flex={true} />
                                                    <InfoBlock
                                                        primaryLabel="Market Price"
                                                        secondaryLabel={
                                                            (productData?.associated_part?.lowest_market_price 
                                                            && new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(productData?.associated_part?.lowest_market_price))
                                                            || "-"
                                                        }
                                                        flex={true} />
                                                    <InfoBlock
                                                        primaryLabel="Distributor Cost"
                                                        secondaryLabel={
                                                            (productData?.associated_part?.estimated_distributor_cost
                                                            && new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(productData?.associated_part?.estimated_distributor_cost))
                                                            || "-"
                                                        }
                                                        flex={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(productData?.associated_part && <div id="strategy-suggestion" className="product-vertical-container v-gap-s">
                                        {/* <ChipRounded label="Reclamation" secondary={true} /> */}
                                        <Label uppercase={true} content="Reclamation" size="s" bold={true} mono={true} secondary={true} />
                                        {(productData?.computed?.spread_two_third * productQuantity) < (productUnitCost * productQuantity) ? (
                                            <InfoBlock
                                                primaryLabel="Estimation"
                                                secondaryLabel="Unlikely to receive offers in less than 6 months at current unit cost."
                                                flex={true} />
                                        ) : productUnitCost <= productData?.computed?.estimated_distributor_cost_half ? (
                                            <div className="product-vertical-container flex 1 v-gap-m">
                                                <InfoBlock
                                                    primaryLabel="Estimation"
                                                    secondaryLabel="1 month to receive an offer that exceeds unit cost."
                                                    flex={true} />
                                                <div className="product-vertical-container flex-1 v-gap-xxs">
                                                    <InfoBlock
                                                        primaryLabel="Estimated Return"
                                                        secondaryLabel={formatToCurrency((productData?.computed?.estimated_distributor_cost_half * (userOverriddenQuantity ?? productQuantity)) || 0)}
                                                        flex={true} />
                                                    <Label content={`Up to ${formatToCurrencyExpanded(productData?.computed?.estimated_distributor_cost_half)} per unit`} size="s" mono={true} secondary={true} />
                                                    <ChipRounded label={`${((((productData?.computed?.estimated_distributor_cost_half * (userOverriddenQuantity ?? productQuantity)) / ((userOverriddenPrice ?? productUnitCost) * (userOverriddenQuantity ?? productQuantity))) * 100) || 0).toFixed(0)}% of unit cost`} positive={true} />                                                </div>
                                            </div>
                                        ) : productUnitCost <= productData?.computed?.spread_one_third ? (
                                            <div className="product-vertical-container flex 1 v-gap-m">
                                                <div className="product-vertical-container flex-1">
                                                    <InfoBlock
                                                        primaryLabel="Estimation"
                                                        secondaryLabel="1-3 months to receive an offer that exceeds unit cost."
                                                        flex={true} />
                                                    {
                                                        productData?.associated_part?.stock_level?.includes("OVER" || "AVERAGE") &&
                                                        productData?.associated_part?.estimated_distributor_cost < productUnitCost &&
                                                        productData?.associated_part?.percent <= 0.5 && (
                                                            <Label content="Reassess unit cost if urgent" size="m" />
                                                        )
                                                    }
                                                </div>
                                                <div className="product-vertical-container flex-1 v-gap-xxs">
                                                    <InfoBlock
                                                        primaryLabel="Estimated Return"
                                                        secondaryLabel={formatToCurrency((productData?.computed?.spread_one_third * (userOverriddenQuantity ?? productQuantity)) || 0)}
                                                        flex={true} />
                                                    <div className="product-vertical-container v-gap-s">
                                                        <Label content={`Up to ${formatToCurrencyExpanded(productData?.computed?.spread_one_third)} per unit`} size="m" mono={true} secondary={true} />                     
                                                        <ChipRounded label={`${((((productData?.computed?.spread_one_third * (userOverriddenQuantity ?? productQuantity)) / ((userOverriddenPrice ?? productUnitCost) * (userOverriddenQuantity ?? productQuantity))) * 100) || 0).toFixed(0)}% of unit cost`} positive={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="product-vertical-container flex 1 v-gap-m">
                                                <div className="product-vertical-container flex-1">
                                                    <InfoBlock
                                                        primaryLabel="Estimation"
                                                        secondaryLabel="3-6+ months to receive an offer that exceeds unit cost."
                                                        flex={true} />
                                                    {
                                                        productData?.associated_part?.stock_level?.includes("OVER" || "AVERAGE") &&
                                                        productData?.associated_part?.estimated_distributor_cost < productUnitCost &&
                                                        productData?.associated_part?.percent <= 0.5 && (
                                                            <p className="strategy-label">Reassess unit cost if urgent</p>
                                                        )
                                                    }
                                                </div>
                                                <div className="product-vertical-container flex-1 v-gap-xxs">
                                                    <InfoBlock
                                                        primaryLabel="Estimated Return"
                                                        secondaryLabel={formatToCurrency((productData?.computed?.spread_two_third * (userOverriddenQuantity ?? productQuantity)) || 0)}
                                                        flex={true} />
                                                    <Label content={`More than ${formatToCurrencyExpanded(productData?.computed?.spread_two_third)} per unit`} size="m" mono={true} secondary={true} />
                                                    <ChipRounded label={`${((((productData?.computed?.spread_two_third * (userOverriddenQuantity ?? productQuantity)) / ((userOverriddenPrice ?? productUnitCost) * (userOverriddenQuantity ?? productQuantity))) * 100) || 0).toFixed(0)}% of unit cost`} positive={true} />
                                                </div>
                                            </div>
                                        )}
                                        {(stockStatus !== "Not Found" || priceStatus !== "Not Found") && <MarketVisuals productData={productData} stockIcon={stockIcon} stockStatus={stockStatus} stockRatio={stockRatio} priceIcon={priceIcon} priceStatus={priceStatus} priceRatio={priceRatio} allAspects={allAspects} uniqueRatio={productData?.associated_part?.percent}/>}
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ProductDetails);
