import './style/TermsAgreement.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setUserTosAccepted } from '../../redux/keystoneInterfaceSlice';
import Label from './Label';
import Button from './Button';

const TermsAgreement = () => {
    const dispatch = useDispatch();
    const keystoneInterfaceAccessToken = useSelector((state) => state?.keystoneInterface?.accessToken);

    const handleAgreeToTerms = () => {
        dispatch(setUserTosAccepted({ accessToken: keystoneInterfaceAccessToken }));
    };

    return (
        <div id="keystone-terms-agreement" className="modal-wrapper product-vertical-container">
            <div className="product-vertical-container pad-xl v-gap-xl">
                <iframe
                    src="https://www.trymaterialize.com/terms-of-use"
                    title="Terms of Use" />
                <div className="product-horizontal-container space-between align-center">
                    <Label content="By continuing, you agree to the terms set forth in the document above." size="m" secondary={true} center={true} />
                    <Button label="Agree" onClick={handleAgreeToTerms} large={true} brand={true} />
                </div>
            </div>
        </div>
    );
};

export default React.memo(TermsAgreement);