import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { schema, normalize } from 'normalizr';
import _ from 'lodash';
import { commitInventory, deleteBankAccount, deleteInvite, fetchBankAccounts, fetchInventory, fetchOffers, fetchPayouts, fetchShipments, fetchTeam, inviteMember, markShipped, removeMember, respondOffer, searchMpn, updateRole } from './keystoneInterfaceSlice';

// Define entities
const associatedPartSchema = new schema.Entity('associatedParts');
const computedSchema = new schema.Entity('computed');

// Define inventory item schema
const inventoryItemSchema = new schema.Entity('inventoryItems', {
    associated_part: associatedPartSchema,
    computed: computedSchema,
});

const inventoryAdapter = createEntityAdapter({
    // Optionally, specify the ID field (default is 'id')
    selectId: (item) => item.id,
    // Optionally, provide a sorter function if you want items sorted in the state
    sortComparer: false, // or provide a custom sorter function
});

const associatedPartsAdapter = createEntityAdapter();
const computedAdapter = createEntityAdapter();

const initialState = {
    calls: [],
    hasFetchedInventory: false,
    inventory: inventoryAdapter.getInitialState(),
    associatedParts: associatedPartsAdapter.getInitialState(),
    computed: computedAdapter.getInitialState(),
};

const upsertConditionally = (subState, normalizedData, adapter, adapterKey) => {
    const existingItems = adapter.getSelectors().selectEntities(subState);
    const newItems = normalizedData.entities[adapterKey];

    let changedItems = [];
    for (const newItemId in newItems) {
        const newItem = newItems[newItemId];
        const existingItem = existingItems[newItemId];

        if (!existingItem || !_.isEqual(existingItem, newItem)) {
            changedItems.push(newItem);
        }
    }
    let removedIds = [];
    for (const existingItemId in existingItems) {
        if (!newItems[existingItemId]) {
            removedIds.push(existingItemId);
        }
    }

    if (removedIds.length > 0) {
        adapter.removeMany(subState, removedIds);
    }

    if (changedItems.length > 0) {
        adapter.upsertMany(subState, changedItems);
    }
}

const upsertInventoryItems = (state, normalizedData) => {
    upsertConditionally(state.inventory, normalizedData, inventoryAdapter, 'inventoryItems');
}

const upsertAssociatedParts = (state, normalizedData) => {
    upsertConditionally(state.associatedParts, normalizedData, associatedPartsAdapter, 'associatedParts');
}

const upsertComputed = (state, normalizedData) => {
    upsertConditionally(state.computed, normalizedData, computedAdapter, 'computed');
}

const upsertNormalizedData = (state, normalizedData) => {
    upsertInventoryItems(state, normalizedData);
    upsertAssociatedParts(state, normalizedData);
    upsertComputed(state, normalizedData);
};

export const keystoneInventorySlice = createSlice({
    name: 'keystoneInventory',
    initialState,
    reducers: {
        setInventory: (state, action) => {
            state.all = action.payload;
        },
        addInventoryItem: (state, action) => {
            state.all.push(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(commitInventory.fulfilled, (state, action) => {
                if (action.payload.inventory_data && action.payload.inventory_data.length > 0) {
                    const normalizedData = processInventory(action.payload);
                    upsertNormalizedData(state, normalizedData);
                }
            });
        builder
            .addCase(fetchInventory.fulfilled, (state, action) => {
                if (action.payload.inventory_data && action.payload.inventory_data.length > 0) {
                    const normalizedData = processInventory(action.payload);
                    upsertNormalizedData(state, normalizedData);
                }
            });
        builder
            .addCase(searchMpn.fulfilled, (state, action) => {
                if (action.payload.normalized_item) {
                    const newRow = {
                        id: 'MTLRZ-KTT-SR-123',
                        unique_id: 'example-id-123',
                        warehouse_name: 'Warehouse #1',
                        manufacturer: 'EX-MFG',
                        mpn: action.payload.searchTerm,
                        quantity: 1,
                        quantity_type: 'Each',
                        unit_value: 0.01,
                        bin_location: 'EX123',
                        description: 'Example internal description',
                        ...action.payload,
                    };
                    const normalizedData = processInventory({ inventory_data: [newRow] });
                    upsertNormalizedData(state, normalizedData);
                }
            });
        builder
            .addCase(fetchOffers.fulfilled, (state, action) => {
                state.offers = formatOffers(action.payload.offers);
            });
        builder
            .addCase(fetchBankAccounts.fulfilled, (state, action) => {
                state.bank_accounts = action.payload.bank_accounts;
            });
        builder
            .addCase(deleteBankAccount.fulfilled, (state, action) => {
                state.bank_accounts = action.payload.bank_accounts;
            });
        builder
            .addCase(respondOffer.fulfilled, (state, action) => {
                state.offers = formatOffers([...state.offers.filter(offer => offer.id !== action.payload.id), action.payload]);
            });
        builder
            .addCase(fetchShipments.fulfilled, (state, action) => {
                state.shipments = action.payload.shipments;
            });
        builder
            .addCase(markShipped.fulfilled, (state, action) => {
                state.shipments = [...state.shipments.filter(shipment => shipment.id !== action.payload.id), action.payload];
            });
        builder
            .addCase(fetchPayouts.fulfilled, (state, action) => {
                state.payouts = action.payload.payouts;
            });
        builder
            .addCase(fetchTeam.fulfilled, (state, action) => {
                state.team_members = action.payload.team_members;
                state.team_invitations = action.payload.invitations;
            });
        builder
            .addCase(inviteMember.fulfilled, (state, action) => {
                state.team_members = action.payload.team_members;
                state.team_invitations = action.payload.invitations;
            });
        builder
            .addCase(deleteInvite.fulfilled, (state, action) => {
                state.team_members = action.payload.team_members;
                state.team_invitations = action.payload.invitations;
            });
        builder
            .addCase(updateRole.fulfilled, (state, action) => {
                state.team_members = action.payload.team_members;
                state.team_invitations = action.payload.invitations;
            });
        builder
            .addCase(removeMember.fulfilled, (state, action) => {
                state.team_members = action.payload.team_members;
                state.team_invitations = action.payload.invitations;
            });
    }
});

const formatOffers = (offers) => {
    const offersFormatted = offers?.map((offer) => {
        const offer_items = offer.offer_items?.map((item) => {
            const marketData = item?.inventory_item?.normalized_item?.metadata?.market_data;
            return {
                ...item,
                dist_times_offer_quantity: item.quantity * (marketData?.estimated_distributor_cost || 0),
                mkt_times_offer_quantity: item.quantity * (marketData?.lowest_market_price || 0),
                min_price_times_offer_quantity: item.quantity * (item.inventory_item?.unit_value || 0)
            };
        });

        const totalDistTimesOfferQuantity = offer_items.reduce((acc, item) => acc + item.dist_times_offer_quantity, 0);
        const totalMktTimesOfferQuantity = offer_items.reduce((acc, item) => acc + item.mkt_times_offer_quantity, 0);
        const minPriceTimesOfferQuantity = offer_items.reduce((acc, item) => acc + item.min_price_times_offer_quantity, 0);
        const totalOfferPercentOfUnitCost = offer.total_price / minPriceTimesOfferQuantity;


        let unique_locations = offer?.unique_locations?.map((item) => {
            return {
                ...item,
                quantity: 0
            }
        });

        offer_items.forEach((item) => {
            const inventory_item = item.inventory_item;
            const existingLocation = unique_locations.find((uniqueLocation) => uniqueLocation.warehouse_name === inventory_item.warehouse_name);
            existingLocation.quantity += item.quantity;
        });

        return {
            ...offer,
            offer_items,
            totalDistTimesOfferQuantity,
            totalMktTimesOfferQuantity,
            minPriceTimesOfferQuantity,
            totalOfferPercentOfUnitCost,
            unique_locations
        }
    });

    return offersFormatted;
};

const processInventory = (data) => {
    const stockWeight = 0.1;
    const uniqueWeight = 0.1;
    const valueWeight = 0.8;

    const totalMinPrice = data.inventory_data?.reduce((acc, item) => {
        return item.unit_value && item.quantity ? acc + (item.unit_value * item.quantity) : acc;
    }, 0);

    let processedData = data.inventory_data?.map((item) => {
        const normalizedItem = item.normalized_item;

        let associated_part = undefined;
        const computed = {};
        computed.total_unit_value = item.unit_value * item.quantity;
        computed.percentage_of_total_min_price = computed.total_unit_value / totalMinPrice;

        if (normalizedItem) {
            const partData = normalizedItem.metadata?.search_data;
            const marketData = normalizedItem.metadata?.market_data;

            let newData = {};

            newData.ending_production = normalizedItem?.metadata?.unique_aspects?.lifecycle_status && (normalizedItem?.metadata?.unique_aspects?.lifecycle_status === "EOL" || normalizedItem?.metadata?.unique_aspects?.lifecycle_status === "NRND");

            newData.ended_production = normalizedItem?.metadata?.unique_aspects?.lifecycle_status && normalizedItem?.metadata?.unique_aspects?.lifecycle_status?.toLowerCase() === "inactive";

            newData.short_description = partData.short_description;

            newData.mpn = normalizedItem.mpn;
            newData.specs = normalizedItem.metadata?.specs;

            newData.avg_total_avail = marketData?.avg_total_avail;
            newData.lowest_market_price = marketData?.lowest_market_price;
            newData.estimated_distributor_cost = marketData?.estimated_distributor_cost;
            newData.sellers_with_priced_offers = marketData?.sellers_with_priced_offers;
            newData.total_avail = marketData?.total_avail;
            newData.total_avail_instant = marketData?.total_avail_instant;
            newData.total_avail_rfq = marketData?.total_avail_rfq;

            const uniqueAspects = normalizedItem.metadata?.unique_aspects;
            if (uniqueAspects.weight) {
                newData.internal_weight_total = uniqueAspects.weight * item.quantity;
            }

            if (uniqueAspects.width) {
                newData.internal_width_total = uniqueAspects.width * item.quantity;
            }

            if (uniqueAspects.length) {
                newData.internal_length_total = uniqueAspects.length * item.quantity;
            }

            if (uniqueAspects.height) {
                newData.internal_height_total = uniqueAspects.height * item.quantity;
            }

            newData.estimated_factory_lead_days = marketData.estimated_factory_lead_days;
            newData.estimated_factory_lead_weeks = marketData.estimated_factory_lead_weeks;

            if (partData.short_description) {
                newData.short_description = partData.short_description;
            }

            if (partData.category?.name) {
                newData.category = partData.category.name;
            }

            if (partData.series?.name) {
                newData.series = partData.series.name;
            }

            newData.stock_level = marketData?.stock_level;

            if (partData.manufacturer) {
                newData.manufacturer = partData.manufacturer.name;
                newData.manufacturer_url = partData.manufacturer.homepage_url;
            }

            computed.spread_delta = newData.estimated_distributor_cost;
            computed.spread_delta_one_third = computed.spread_delta / 3;
            computed.spread_delta_two_third = (computed.spread_delta / 3) * 2;
            computed.spread_one_third = computed.spread_delta_one_third + newData.estimated_distributor_cost;
            computed.spread_two_third = computed.spread_delta_two_third + newData.estimated_distributor_cost;
            computed.estimated_distributor_cost_half = newData.estimated_distributor_cost * 0.5;

            const averageQuantity = Math.max(0, newData.avg_total_avail);
            const actualQuantity = Math.max(0, newData.total_avail);
            let stockRatio = averageQuantity / actualQuantity;

            if (averageQuantity === 0 && actualQuantity === 0) {
                stockRatio = 1;
            } else if (actualQuantity === 0) {
                stockRatio = 1;
            } else {
                stockRatio = 1 - (1 / (1 + stockRatio));
            }

            computed.stock_ratio = stockRatio * stockWeight;

            let competitivenessScore = 0;

            if (item.unit_value <= 0 || newData.estimated_distributor_cost <= 0) {
                competitivenessScore = 0.5;
            } else {
                // Calculate the ratio of estimated distributor cost to product unit cost
                const ratio = newData.estimated_distributor_cost / item.unit_value;

                // Use a non-linear function to compute the competitiveness score
                competitivenessScore = 1 - (1 / (1 + ratio));

                // Ensure the competitiveness score is between 0 and 1
                competitivenessScore = Math.max(0, Math.min(1, competitivenessScore));
            }

            computed.price_ratio = competitivenessScore;

            // computed.score = computed.stock_ratio + computed.price_ratio;
            computed.score = computed.stock_ratio + (uniqueAspects.percent * uniqueWeight || 0);

            associated_part = {
                ...newData,
                ...uniqueAspects
            };
            // if score is NaN log
            // if (isNaN(computed.score)) {
            //     console.log("score is NaN for item: ", item);
            // }
        }

        const output = {
            id: item.id,
            unique_id: item.unique_id,
            mpn: item.mpn,
            manufacturer: item.manufacturer,
            description: item.description,
            condition: item.condition,
            unit_value: item.unit_value,
            quantity: item.quantity,
            quantity_type: item.quantity_type,
            normalization_status: item.normalization_status,
            warehouse_name: item.warehouse_name,
            computed,
            associated_part
        };

        return output;
    });

    const highestPercentOfTotalMinPrice = processedData?.reduce((acc, item) => {
        return item.computed?.percentage_of_total_min_price > acc ? item.computed?.percentage_of_total_min_price : acc;
    }, 0);

    const lowestPercentOfTotalMinPrice = processedData?.reduce((acc, item) => {
        return item.computed?.percentage_of_total_min_price < acc ? item.computed?.percentage_of_total_min_price : acc;
    }, 0);

    // add value ratio to each item using the highest and lowest values and clamp between 0 and 1
    processedData?.forEach(item => {
        if (item.computed) {
            item.computed.value_ratio = (item.computed?.percentage_of_total_min_price - lowestPercentOfTotalMinPrice) / (highestPercentOfTotalMinPrice - lowestPercentOfTotalMinPrice);
            item.computed.score = item.computed?.score + (item.computed?.value_ratio * valueWeight);

            // if item.computed.score is NaN log
            // if (isNaN(item.computed.score)) {
            //     console.log("score is NaN for item: ", item);
            // }
        }
        return item;
    });

    const highestScore = processedData?.reduce((acc, item) => {
        return item.computed?.score > acc ? item.computed?.score : acc;
    }, 0);

    const lowestScore = processedData?.reduce((acc, item) => {
        return item.computed?.score < acc ? item.computed?.score : acc;
    }, 0);

    // add a score_clamped to computed object for each item using the highest and lowest values and clamp between 0 and 1
    function hashCode(string){
        var hash = 0;
        for (var i = 0; i < string.length; i++) {
            var code = string.charCodeAt(i);
            hash = ((hash<<5)-hash)+code;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }
    function genId(obj) {
        const hash = hashCode(JSON.stringify(obj));
        return hash > 0 ? `${hash}` : `1${hash}`;
    }
    processedData?.forEach(item => {
        if (!item.id) {
            item.id = genId(item);
        }

        if (item.computed) {
            item.computed.score_clamped = (item.computed?.score - lowestScore) / (highestScore - lowestScore);
            item.computed.id = `computed_${item.id}`;
        }

        if (item.associated_part) {
            item.associated_part.id = `associated_part_${item.id}`;
        }
        return item;
    });

    // After computing, normalize the data
    const normalizedData = normalize(processedData, [inventoryItemSchema]);

    return normalizedData;
}

export const selectInventoryState = (state) => state.keystoneInventory.inventory;
export const selectAssociatedPartsState = (state) => state.keystoneInventory.associatedParts;
export const selectComputedState = (state) => state.keystoneInventory.computed;

// Selector to get all inventory items
export const selectAllInventoryItems = createSelector(
    selectInventoryState,
    (inventoryState) => inventoryAdapter.getSelectors().selectAll(inventoryState)
);

// Selector to get inventory item by ID
export const makeSelectInventoryItemById = () => {
    return createSelector(
        [
            (state, itemId) => inventoryAdapter.getSelectors().selectById(selectInventoryState(state), itemId),
            selectAssociatedPartsState,
            selectComputedState
        ],
        (item, associatedPartsState, computedState) => {
            if (!item) return null;

            const associated_part = associatedPartsAdapter.getSelectors().selectById(associatedPartsState, item.associated_part);
            const computed = computedAdapter.getSelectors().selectById(computedState, item.computed);

            return { ...item, associated_part, computed };
        }
    );
};

// Selector to get all inventory items with associated data
export const selectAllInventoryItemsWithData = createSelector(
    [selectAllInventoryItems, selectAssociatedPartsState, selectComputedState],
    (items, associatedPartsState, computedState) => {
        const associatedPartsById = associatedPartsAdapter.getSelectors().selectEntities(associatedPartsState);
        const computedById = computedAdapter.getSelectors().selectEntities(computedState);

        return items.map(item => {
            const associated_part = associatedPartsById[item.associated_part];
            const computed = computedById[item.computed];
            return { ...item, associated_part, computed };
        });
    }
);

export const selectAllInventoryCount = createSelector(
    [selectAllInventoryItems],
    (items) => items.length
);

export const selectWarehouseLocationCounts = createSelector(
    [selectAllInventoryItems],
    (items) => {
        const uniqueLocations = {};
        items.forEach(item => {
            if (item.id === 'MTLRZ-KTT-SR-123') {
                return; // Skip temporary items
            }

            if (!uniqueLocations[item.warehouse_name]) {
                uniqueLocations[item.warehouse_name] = 0;
            }
            uniqueLocations[item.warehouse_name]++;
        });
        return uniqueLocations;
    }
);

export const { setInventory } = keystoneInventorySlice.actions;
export const { addInventoryItem } = keystoneInventorySlice.actions;

export const selectKeystoneInterfaceState = (state, key) => {
    return state.keystoneInterface[key];
};

export default keystoneInventorySlice.reducer;