import './style/Menu.css';
import React, { useEffect, useState, useRef } from 'react';
import CaretRight32px from '../../media/caret-right32px@3x.png';
import CaretLeft32px from '../../media/caret-left32px@3x.png';
import Home32px from '../../media/home-32px@3x.png';
import Speaker32px from '../../media/speaker32px@3x.png';
import Truck32px from '../../media/truck32px@3x.png';
import Bill32px from '../../media/bill32px@3x.png';
import Team32px from '../../media/team32px@3x.png';
import Gear32px from '../../media/gear32px@3x.png';
import AppIcon from '../../media/app-icon.png';
import MenuButton from './MenuButton';
import Button from './Button';
import EllipsisCircle from '../../media/ellipsis-circle@3x.png';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Label from './Label';
import { setShowModal, setModalType } from '../../redux/keystoneInterfaceSlice';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';
import Action from './Action';
import { useParams } from 'react-router-dom';

const Menu = ({ menuOpen, setMenuOpen, className, mobile, handleLogout }) => {
    const keystoneInterfaceIsLoggedIn = useSelector((state) => state?.keystoneInterface?.isLoggedIn);
    const {
        tooltipData,
        tooltipLeft,
        tooltipTop,
        tooltipOpen,
        showTooltip,
        hideTooltip,
      } = useTooltip();

    const dispatch = useDispatch();
    const logoutTooltipRef = useRef(null);
    const logoutButtonRef = useRef(null);
    const { page } = useParams();
    const [logoutMenuVisible, setLogoutMenuVisible] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (logoutTooltipRef.current && !logoutTooltipRef.current.contains(event.target) && !logoutButtonRef.current.contains(event.target) && logoutMenuVisible) {
                setLogoutMenuVisible(false);
                hideTooltip();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [hideTooltip, logoutButtonRef, logoutMenuVisible]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }
    
    const handleUserMenuClick = (event) => {
        event.stopPropagation();
        if (!logoutMenuVisible) {
            showTooltip({
                tooltipData: logoutButton,
                tooltipLeft: event.clientX,
                tooltipTop: event.clientY,
            });
        } else {
            hideTooltip();
        }
        setLogoutMenuVisible(!logoutMenuVisible);
    };

    const handleShowLogin = () => {
        dispatch(setModalType("create-account"));
        dispatch(setShowModal(true));
    };


    const logoutButton = (
        <Action reference={logoutTooltipRef} onClick={handleLogout} label="Logout" />
    ); 

    return !mobile && (
        <div id="keystone-menu" className={className + " relative"}>
            <div id="menu-navigation">
                <button id="menu-collapse" onClick={toggleMenu}>
                    <img id="menu-collapse-icon" src={menuOpen ? CaretLeft32px : CaretRight32px} alt="caret-right32px" />
                </button>
                <div id="menu-company">
                    <img id="company-logo" src={AppIcon} alt="caret-right32px" />
                    <Label content="Materialize" size="m" bold={true} displayFont={true} />
                </div>
            </div>
            <div id="menu-content" className={className}>
                <MenuButton label="Inventory" icon={Home32px} menuOpen={menuOpen} />
                <MenuButton label="Offers" icon={Speaker32px} menuOpen={menuOpen} />
                <MenuButton label="Shipments" icon={Truck32px} menuOpen={menuOpen} />
                <MenuButton label="Payouts" icon={Bill32px} menuOpen={menuOpen} />
                <MenuButton label="Team" icon={Team32px} menuOpen={menuOpen} />
                <MenuButton label="Manage" icon={Gear32px} menuOpen={menuOpen} />
            </div>
                {keystoneInterfaceIsLoggedIn ? (
                    <div id="menu-footer" className="product-horizontal-container width-100 space-between">
                        <div id="mobile-page-title" className="product-horizontal-container align-center h-gap-l flex-1">
                            <Label content={page?.charAt(0).toUpperCase() + page?.slice(1)} size="l" bold={true} displayFont={true} />
                        </div>
                        <div className="product-horizontal-container align-center h-gap-m">
                            <div id="menu-user-avatar">
                                <Label content="EB" size="m" mono={true} bold={true} secondary={true} />
                            </div>
                            <div id="menu-user">
                                <Label content="Evan Bashir" size="s" displayFont={true} bold={true} />
                                <Label content="Founder" size="xs" displayFont={true} bold={true} secondary={true} />
                            </div>
                        </div>
                        {menuOpen && (
                            <Button icon={EllipsisCircle}
                                customId="logout-overflow-menu"
                                iconSize={28}
                                onClick={(e) => handleUserMenuClick(e)}
                                showTooltipContent={false}
                                reference={logoutButtonRef} />
                        )}
                        {tooltipOpen && (
                            <TooltipWithBounds
                            // set this to random so it correctly updates with parent bounds
                                key={Math.random()}
                                top={tooltipTop}
                                left={tooltipLeft}
                                children={tooltipData}
                            >
                            </TooltipWithBounds>
                        )}
                    </div>
                ) : (
                    <div id="menu-footer" className="product-horizontal-container width-100 space-between">
                        <Button label="Create Account" brand="true" flex={true} large={true} onClick={handleShowLogin} />
                    </div>
                )}
        </div>
    );
};

export default React.memo(Menu);