import './style/Shipments.css';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { openTrayTypeWithData } from '../../redux/keystoneSlice';
import { makeSelectInventoryItemById } from '../../redux/keystoneInventorySlice';
import Row from './Row';
import Placeholder from './Placeholder';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { normalizeTabName, fetchShipments } from '../../redux/keystoneInterfaceSlice';

const Shipments = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const keystoneInterfaceShipmentsTabs = useSelector((state) => state?.keystoneInterface?.shipmentsTabs);
    const keystoneInterfaceAccessToken = useSelector((state) => state?.keystoneInterface?.accessToken);
    const keystoneInventoryShipments = useSelector((state) => state?.keystoneInventory?.shipments);

    const { search } = useLocation();
    const selectedObjectId = new URLSearchParams(search).get('id') || null;
    const selectInventoryItemById = useMemo(() => makeSelectInventoryItemById(), []);
    const selectedObject = useSelector(state => selectInventoryItemById(state, selectedObjectId));
    const { page, tabName } = useParams();

    const keystoneInterfaceShipmentsTab = keystoneInterfaceShipmentsTabs.map(normalizeTabName).indexOf(normalizeTabName(tabName));
    useEffect(() => {
        if (keystoneInterfaceShipmentsTab < 0) {
            navigate(`/${page}/${normalizeTabName(keystoneInterfaceShipmentsTabs?.[0])}`);
        }
    }, [keystoneInterfaceShipmentsTab, keystoneInterfaceShipmentsTabs, page, navigate]);

    useEffect(() => {
        dispatch(fetchShipments({ accessToken: keystoneInterfaceAccessToken }));
    }, [keystoneInterfaceAccessToken, dispatch]);

    useEffect(() => {
        if (selectedObject) {
            dispatch(openTrayTypeWithData({
                trayOpen: true,
                trayData: selectedObject,
                trayType: "shipment"
            }));
        }
    }, [dispatch, selectedObject]);

    return (
        <div className="keystone-shipments list-container product-vertical-container pad-m v-gap-s flex-1">
            {keystoneInventoryShipments?.filter(shipment =>
                (keystoneInterfaceShipmentsTab === 0 && shipment.status === "PENDING") ||
                (keystoneInterfaceShipmentsTab === 1 && shipment.status === "SHIPPED") ||
                (keystoneInterfaceShipmentsTab === 2 && shipment.status === "COMPLETED")).map((shipment, index) => (
                <Row
                    key={shipment.id}
                    index={shipment.id}
                    primaryLabel={`Shipment #${shipment.id}`}
                    secondaryLabel={`Quantity: ${shipment.offer?.total_quantity} - Unique Parts: ${shipment.related_offer_items?.length}`}
                    navigateOnClick={true}
                    tags={[{
                        label: shipment.offer?.total_price,
                        secondary: false,
                        format: "usd"
                    }]} />
            ))}
            {keystoneInterfaceShipmentsTab === 0 && !keystoneInventoryShipments?.some(shipment => (
                shipment.status === "PENDING")
            ) && (
                <Placeholder label="No items left to ship" />
            )}
            {keystoneInterfaceShipmentsTab === 1 && !keystoneInventoryShipments?.some(shipment => (
                shipment.status === "SHIPPED")
            ) && (
                <Placeholder label="No active items being shipped" />
            )}
            {keystoneInterfaceShipmentsTab === 2 && !keystoneInventoryShipments?.some(shipment => (
                shipment.status === "COMPLETED"
            )) && (
                <Placeholder label="No completed shipments yet" />
            )}
        </div>
    );
};

export default React.memo(Shipments);