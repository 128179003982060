import './style/InventorySummary.css';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PieChart from './PieChart';
import { } from '../../redux/keystoneSlice';
import { setFilteredInventoryIds } from '../../redux/keystoneInterfaceSlice.js';
import TreeChart from './TreeChart';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from './Button';
import Label from './Label';
import SimpleLoader from './SimpleLoader';
import { formatToCurrency } from '../../util/Calculate.js';
import Back from '../../media/back@3x.png';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { useScreenSize } from '@visx/responsive';
import TabGroup from './TabGroup.js';
import { useEvent as useEffectEvent } from '@reactuses/core';
import { selectAllInventoryItemsWithData } from '../../redux/keystoneInventorySlice.js';
import _ from 'lodash';

const InventorySummary = () => {
    const { width } = useScreenSize({ debounceTime: 150 });

    const [currentScreenWidth, setCurrentScreenWidth] = useState(0);
    const { pathname } = useLocation();
    const { page, tabName } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const keystoneInterfaceFilteredInventoryIds = useSelector((state) => state?.keystoneInterface?.filteredInventoryIds);
    const keystoneInventoryAll = useSelector(selectAllInventoryItemsWithData);

    const [renderDepth, setRenderDepth] = useState(2);
    const [currentTreeNode, setCurrentTreeNode] = useState(null);
    const [hoveredTreeNode, setHoveredTreeNode] = useState(null);
    const [sortBy, setSortBy] = useState("Book Value");

    const { marketPieData, categoriesAndFrequency, hierarchyData, mfgTotalValue, selectedMfgTotalValue } = useMemo(() => {
        let marketPieData = [];
        let categoriesAndFrequency = [];
        let mfgTotalValue = 0;
        let selectedMfgTotalValue = 0;
        let hierarchyData = [];
        if (keystoneInventoryAll?.length > 0) {
            /* MARKET PIE DATA */
            const numberWithoutAssociatedParts = keystoneInventoryAll?.filter((item) => !item?.associated_part).length;
            const totalParts = keystoneInventoryAll?.length;
            marketPieData = ([
                {
                    label: "Market Data Found",
                    value: (totalParts - numberWithoutAssociatedParts) / totalParts
                },
                {
                    label: "Processing",
                    value: numberWithoutAssociatedParts / totalParts
                }
            ])

            /* CATEGORY DISTRIBUTION DATA */
            const categoryFrequency = {};
            keystoneInventoryAll?.forEach((item) => {
                if (item?.associated_part?.category) {
                    if (categoryFrequency[item?.associated_part?.category]) {
                        categoryFrequency[item?.associated_part?.category] += 1;
                    } else {
                        categoryFrequency[item?.associated_part?.category] = 1;
                    }
                }
            });
            let categoriesAndFrequencyData = Object.keys(categoryFrequency).map((category) => {
                return {
                    category,
                    frequency: categoryFrequency[category]
                }
            }).sort((a, b) => b.frequency - a.frequency);
            
            // count of items without category
            // const numberWithoutCategory = keystoneInventoryAll?.filter((item) => !item?.associated_part?.category).length;

            // set categoriesAndFrequency to the top 5 categories where 5th category is "Other" summing up the rest
            const top5Categories = categoriesAndFrequencyData.slice(0, 4);
            // const otherCategories = categoriesAndFrequency.slice(2);
            // const otherCategoriesFrequency = otherCategories.reduce((acc, category) => acc + category.frequency, 0);
            // top5Categories.push({ category: "Other", frequency: otherCategoriesFrequency + numberWithoutCategory });

            const totalCategoryFrequencies = top5Categories.reduce((acc, category) => acc + category.frequency, 0);

            // array where label is category and value is frequency
            const categoryPieData = top5Categories.map((category) => {
                return {
                    label: category.category,
                    value: category.frequency / totalCategoryFrequencies
                }
            });
            categoriesAndFrequency = categoryPieData;

            /* BOX PLOT (MARKET VALUE vs. UNIT VALUE by MFG) */
            // {
            //     manufacturer: 'Manufacturer A',
            //     items: [
            //         { partNumber: 'A1', marketPrice: 120, bookValue: 100 },
            //         { partNumber: 'A2', marketPrice: 130, bookValue: 110 },
            //         { partNumber: 'A3', marketPrice: 115, bookValue: 105 },
            //     ],
            // },
            

            // make array of objects with manufacturer and array of items that have marketPrice and bookValue and partNumber
            let arrayOfManufacturersWithItems = keystoneInventoryAll?.map((item) => {
                if (item?.associated_part?.manufacturer) {
                    return {
                        manufacturer: item?.associated_part?.manufacturer,
                        items: [
                            {
                                id: item?.id,
                                partNumber: item?.associated_part?.mpn,
                                marketPrice: item?.associated_part?.lowest_market_price,
                                totalMarketPrice: item?.associated_part?.lowest_market_price * item?.quantity,
                                bookValue: item?.unit_value,
                                totalBookValue: item?.unit_value * item?.quantity,
                            }
                        ]
                    }
                } else {
                    return null;
                }
            }).reduce((acc, item) => {
                if (!item) return acc;
                let manufacturer = acc.find((manufacturer) => manufacturer.manufacturer === item.manufacturer);
                if (manufacturer) {
                    manufacturer.items.push(item.items[0]);
                } else {
                    acc.push(item);
                }
                return acc;
            }, []).sort((a, b) => b.items.length - a.items.length);

            // setBoxPlotData(arrayOfManufacturersWithItems);

            /* HIERARCHY DATA */
            for (let manufacturer of arrayOfManufacturersWithItems) {
                let mfgData = {
                    id: manufacturer.manufacturer,
                    label: manufacturer.manufacturer,
                    parent: 'Manufacturers',
                    size: null,
                };

                let mfgItems = [];

                for (let item of manufacturer.items) {
                    const bookValue = item.totalBookValue;
                    const marketValue = item.totalMarketPrice;
                    const value = sortBy === "Book Value" ? bookValue : marketValue;
                    mfgItems.push({
                        id: item.id,
                        label: item.partNumber,
                        parent: manufacturer.manufacturer,
                        size: value,
                        rawSize: value,
                        formattedSize: formatToCurrency(value),
                    });
                }

                const mfgTotal = mfgItems.reduce((acc, item) => acc + item.size, 0);
                mfgData.rawSize = mfgTotal;
                mfgData.formattedSize = formatToCurrency(mfgTotal);
                hierarchyData.push(mfgData);
                hierarchyData = hierarchyData.concat(mfgItems);
            }

            hierarchyData.push({
                id: 'Inventory',
                parent: null,
                size: null,
            });
            
            const totalValue = hierarchyData.reduce((acc, item) => {
                if (item.parent === 'Manufacturers') {
                    return acc + item.rawSize;
                }
                return acc;
            }, 0);

            hierarchyData.push({
                id: 'Manufacturers',
                parent: 'Inventory',
                size: null,
                formattedSize: totalValue,
                rawSize: totalValue,
            });

            mfgTotalValue = totalValue;

            const totalValueForSelectedMfg = hierarchyData.reduce((acc, item) => {
                if (item?.parent === currentTreeNode?.data.id) {
                    return acc + item.rawSize;
                }
                return acc;
            }, 0);

            selectedMfgTotalValue = totalValueForSelectedMfg;
        }
        return { marketPieData, categoriesAndFrequency, hierarchyData, mfgTotalValue, selectedMfgTotalValue };
    }, [keystoneInventoryAll, sortBy, currentTreeNode]);

    const handleBack = useEffectEvent(() => {
        setCurrentTreeNode(currentTreeNode?.parent);
        updateFilteredChildren();
        setRenderDepth((currentTreeNode?.parent?.depth || 1) + 1)
    });

    const updateFilteredChildren = useCallback(() => {
        const findChildrenIds = (node) => {
            if (node?.depth === 3) {
                return [node.data.id];
            } else if (node?.children) {
                return node.children.map((child) => findChildrenIds(child)).reduce((acc, val) => acc.concat(val), []);
            }
        };

        const childrenIds = findChildrenIds(currentTreeNode);
        if (!_.isEqual(childrenIds, keystoneInterfaceFilteredInventoryIds)) {
            dispatch(setFilteredInventoryIds(childrenIds));
        }
    }, [currentTreeNode, dispatch, keystoneInterfaceFilteredInventoryIds]);

    useEffect(() => {
        const findChildrenIds = (node) => {
            if (node?.depth === 3) {
                return [node.data.id];
            } else if (node?.children) {
                return node.children.map((child) => findChildrenIds(child)).reduce((acc, val) => acc.concat(val), []);
            }
        };

        const childrenIds = findChildrenIds(currentTreeNode);
        dispatch(setFilteredInventoryIds(childrenIds));
    }, [currentTreeNode, dispatch]);

    const handleTileClicked = useEffectEvent((node) => {
        if (renderDepth === 3) {
            const params = new URLSearchParams();
            params.set('id', node.data.id);
            navigate(`${page ? `/${page}` : pathname}${tabName ? `/${tabName}` : ""}?${params.toString()}`);
        } else {
            setCurrentTreeNode(node);
            updateFilteredChildren();
        }
    });

    useEffect(() => {
        console.log("currentScreenWidth: ", width);
        if (width !== currentScreenWidth) {
            setCurrentScreenWidth(width);
        }
    }, [currentScreenWidth, width]);

    return (
        <div className="product-horizontal-container flex-1 width-100 min-h-l max-h-l">
            <ParentSize className="product-horizontal-container flex-1 width-100 min-h-l max-h-l pad-m no-pad-bot" debounceTime={10}>{({ width, height }) => (
                <div className="product-horizontal-container h-gap-m flex-1">
                    <div className="relative" style={{width: currentScreenWidth < 767 ? width : ((width / 5) * 3 - 12), height: height}}>
                        <TreeChart width={currentScreenWidth < 767 ? width : ((width / 5) * 3 - 12)} height={height} rawData={hierarchyData} currentTreeNode={currentTreeNode} handleTileClicked={(e) => handleTileClicked(e)} renderDepth={renderDepth} setRenderDepth={setRenderDepth} hoveredTreeNode={hoveredTreeNode} setHoveredTreeNode={setHoveredTreeNode} />
                        <div className="overlay-tools bottom-left product-horizontal-container flex-1 align-center justify-center">
                            <div className="product-horizontal-container flex-1">
                                {renderDepth > 2 && <Button icon={Back} iconSize={16} iconColor="white" transparent={true} small={true} onClick={() => handleBack()} />}
                            </div>
                            <div className="product-horizontal-container flex-1 justify-center">
                                <div className="product-vertical-container align-center">
                                    {hierarchyData?.length > 0 && (
                                        <Label maxContent={true} center={true} content={hoveredTreeNode ? hoveredTreeNode?.data?.data?.label : renderDepth === 2 ? "All Manufacturers" : currentTreeNode?.data?.data?.label} size="s" bold={true} displayFont={true} />
                                    )}
                                    {hierarchyData?.length > 0 && (
                                        <Label maxContent={true} center={true} content={hoveredTreeNode ? hoveredTreeNode?.data?.data?.formattedSize : renderDepth === 2 ? formatToCurrency(mfgTotalValue) : formatToCurrency(selectedMfgTotalValue)} size="s" mono={true} />
                                    )}
                                </div>
                            </div>
                            {hierarchyData?.length > 0 && (
                                <TabGroup tabs={["Book Value", "Market Value"]} navigation={false} handleClick={(label) => setSortBy(label)} currentTab={sortBy} />
                            )}
                        </div>
                        <SimpleLoader loading={hierarchyData?.length === 0} />
                    </div>
                    <PieChart width={currentScreenWidth < 767 ? width / 2 : ((width / 5) - 6)} height={height} data={categoriesAndFrequency} loading={categoriesAndFrequency?.length === 0} showPercent={false} />
                    <PieChart width={currentScreenWidth < 767 ? width / 2 : ((width / 5) - 6)} height={height} data={marketPieData} loading={marketPieData?.length === 0} showPercent={true} />
                </div>
            )}</ParentSize>
        </div>
    );
};

export default React.memo(InventorySummary);