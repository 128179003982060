import './style/Tab.css';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setNotifications } from '../../redux/keystoneSlice';

const Tab = ({ index, label, active, navigation = false, handleClick }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { page } = useParams();

    // TODO: The reason we're grabbing the whole keystoneInterface state is because we're trying to dynamically generate the tabName
    // from the set of keys that are in the state, which means we depend on the whole thing. There's probably a better way to do this,
    // but for now we'll just pull the whole state. Don't do this in new code please!
    const keystoneInterface = useSelector((state) => state?.keystoneInterface);

    const handleTabChange = useCallback(() => {
        if (navigation) {
            dispatch(setNotifications(keystoneInterface?.notifications?.filter(notif => notif.page !== page && notif.tab !== index)));
            const tabName = keystoneInterface?.[page + "Tabs"][index]?.replaceAll(' ', '-')?.toLowerCase();
            navigate(`/${page}/${tabName}`);
        } else {
            handleClick(label);
        }
    }, [navigation, dispatch, keystoneInterface, page, index, navigate, handleClick, label]);

    return (
        <div className="keystone-tab">
            <button className={`ks-button tab ${active && 'active'} size-s justify-center`} onClick={handleTabChange}>{label}</button>
        </div>
    );
};

export default React.memo(Tab);