import './style/ManageSection.css';
import React, { useState, useEffect } from 'react';
import Row from './Row';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { deleteBankAccount, setModalType, setShowModal } from '../../redux/keystoneInterfaceSlice';
import { setCompanyOffersAccepted, setAddBankAccount, setUsersOffersNotify } from '../../redux/keystoneInterfaceSlice';
import Label from './Label';
import { useEvent as useEffectEvent } from '@reactuses/core';

const ManageSection = ({ name, manage }) => {
    const keystoneInterfaceAddBankAccount = useSelector((state) => state?.keystoneInterface?.addBankAccount);
    const keystoneInterfaceCalls = useSelector((state) => state?.keystoneInterface?.calls);
    const keystoneInterfaceIsLoggedIn = useSelector((state) => state?.keystoneInterface?.isLoggedIn);
    const keystoneInterfaceAccessToken = useSelector((state) => state?.keystoneInterface?.accessToken);
    const keystoneInventoryBankAccounts = useSelector((state) => state?.keystoneInventory?.bank_accounts);
    const keystoneInterfaceOffersAccepted = useSelector((state) => state?.keystoneInterface?.offersAccepted);
    const keystoneInterfaceOffersNotify = useSelector((state) => state?.keystoneInterface?.offersNotify);
    
    // TODO: The reason we're grabbing the whole keystoneInterface state is because we're trying to dynamically generate the settings
    // from the set of keys that are in the state, which means we depend on the whole thing. There's probably a better way to do this,
    // but for now we'll just pull the whole state. Don't do this in new code please!
    const keystoneInterface = useSelector((state) => state?.keystoneInterface);

    const dispatch = useDispatch();
    const [indexLoading, setIndexLoading] = useState(null);


    useEffect(() => {
        if (!keystoneInterfaceAddBankAccount
            && indexLoading !== null 
            && manage?.[indexLoading]?.state === "payoutsSetup") {
            setIndexLoading(null);
        }
    }, [indexLoading, keystoneInterfaceAddBankAccount, manage]);

    useEffect(() => {
        if (keystoneInterfaceCalls?.some(item =>
            item.type === "company" &&
            item.action === "offers accepted" &&
            (item.status === "fulfilled" || item.status === "rejected")
        )) {
            setIndexLoading(null);
        }

        if (keystoneInterfaceCalls?.some(item =>
            item.type === "user" &&
            item.action === "offers notifications" &&
            (item.status === "fulfilled" || item.status === "rejected")
        )) {
            setIndexLoading(null);
        }
    }, [keystoneInterfaceCalls]);

    const handleInvertSetting = useEffectEvent((index, setting) => {
        if (!keystoneInterfaceIsLoggedIn) {
            // TODO: Enable locations after built
            // dispatch(setModalType("locations-setup"));
            // dispatch(setShowModal(true));

            // TODO: Do post location setup
            dispatch(setModalType("create-account"));
            dispatch(setShowModal(true));
            return;
        }

        setIndexLoading(index);
        if (setting.state === "offersAccepted") {
            dispatch(setCompanyOffersAccepted({ accessToken: keystoneInterfaceAccessToken, accepted: !keystoneInterfaceOffersAccepted }));
        } else if (setting.state === "payoutsSetup" && !keystoneInventoryBankAccounts?.length) {
            dispatch(setAddBankAccount(true));
        } else if (setting.state === "payoutsSetup" && keystoneInventoryBankAccounts?.length) {
            dispatch(deleteBankAccount({ accessToken: keystoneInterfaceAccessToken, id: keystoneInventoryBankAccounts?.[0]?.id }));
        } else if (setting.state === "offersNotify") {
            dispatch(setUsersOffersNotify({ accessToken: keystoneInterfaceAccessToken, enabled: !keystoneInterfaceOffersNotify }));
        } else if (setting.state === "boxMinimum") {
            dispatch(setShowModal(true));
            dispatch(setModalType("box-minimum"));
            setIndexLoading(null);
        } else {
            setIndexLoading(null);
        }
    });

    return (
        <div className="keystone-setting-section product-vertical-container v-gap-s">
            <div className="product-vertical-container pad-m no-pad-bot">
                <Label content={name} size="l" bold={true} displayFont={true} />
            </div>
            {manage.map((setting, index) => {
                return (<Row
                    key={setting.id}
                    index={setting.id}
                    primaryLabel={setting.primaryLabel}
                    primaryLabelAlternate={setting.primaryLabelAlternate}
                    secondaryLabel={setting.secondaryLabel}
                    secondaryLabelAlternate={setting.secondaryLabelAlternate}
                    secondary={setting.placeholder}
                    button={{
                        label: setting.ctaLabel,
                        labelAlternate: setting.ctaLabelAlternate,
                        brand: true,
                        onClick: () => handleInvertSetting(index, setting),
                        loading: indexLoading === index,
                        disabled: setting.disabled || false
                    }}
                    showAlternate={keystoneInterface?.[setting.state]} />);
                })}
        </div>
    );
};

export default React.memo(ManageSection);