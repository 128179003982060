import './style/MenuButton.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setNotifications } from '../../redux/keystoneSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useEvent as useEffectEvent } from '@reactuses/core';

const MenuButton = ({ icon, label, menuOpen }) => {
    const keystoneNotifications = useSelector((state) => state?.keystone?.notifications);

    // TODO: The reason we're grabbing the whole keystoneInterface state is because we're trying to dynamically generate the tabs
    // from the set of keys that are in the state, which means we depend on the whole thing. There's probably a better way to do this,
    // but for now we'll just pull the whole state. Don't do this in new code please!
    const keystoneInterface = useSelector((state) => state?.keystoneInterface);


    const { page } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const handleClick = useEffectEvent(() => {
        dispatch(setNotifications(keystoneNotifications?.filter(notif => notif.page !== label.toLowerCase() && notif.tab !== 0)));
        const tab = keystoneInterface?.[label.toLowerCase() + 'Tabs']?.[0];
        let url = `/${label.toLowerCase()}${tab ? `/${tab.toLowerCase()?.replace(" ", "-")}` : ""}`;
        navigate(url);
    });

    return (
        <button className={page === label.toLowerCase() ? 'keystone-menu-button content-row active relative' : 'keystone-menu-button content-row relative'} onClick={() => handleClick()}>
            {keystoneNotifications?.find(notif => notif.page === label.toLowerCase()) && <div className="keystone-notification-circle"></div>}
            {menuOpen && <p className="row-label size-s">{label}</p>}
            <img className="row-icon" src={icon} alt={`${label} Page Icon`} />
        </button>
    );
};

export default React.memo(MenuButton);