import React, { useEffect, useState } from 'react';
import { Group } from '@visx/group';
import { Treemap, hierarchy, stratify, treemapSquarify} from '@visx/hierarchy';
import { scaleLinear } from '@visx/scale';
import { PatternLines } from '@visx/pattern';
import SimpleLoader from './SimpleLoader';

// Define the tile methods
const tileMethods = {
  treemapSquarify,
};

const TreeChart = ({ rawData, width, height, loading, handleTileClicked, renderDepth, setRenderDepth, hoveredTreeNode, setHoveredTreeNode, currentTreeNode }) => {
    // Stratify the data
    const [data, setData] = useState(null); 
    const [selectedNodeId, setSelectedNodeId] = useState(false);

    const [xMax, setXMax] = useState(0);
    const [yMax, setYMax] = useState(0);
    const [root, setRoot] = useState(null);


    // Default margin
    const margin = { top: 48, left: 12, right: 12, bottom: 12 };

    useEffect(() => {
        if (data === null && rawData?.length > 0) {
            const newData = stratify()
                .id((d) => d.id)
                .parentId((d) => d.parent)(rawData)
                .sum((d) => d.size || 0);
            setData(newData);
        }
    }, [data, rawData]);

    useEffect(() => {
        if (data?.children) {
            const xMax = width - margin.left - margin.right;
            setXMax(xMax);
            const yMax = height - margin.top - margin.bottom;
            setYMax(yMax);
            const root = hierarchy(data).sort((a, b) => (b.value || 0) - (a.value || 0));
            setRoot(root);
        }
    }, [data, height, margin.bottom, margin.left, margin.right, margin.top, width]);

    useEffect(() => {
      if (renderDepth === 2 && rawData?.length > 0) {
        const newData = stratify()
          .id((d) => d.id)
          .parentId((d) => d.parent)(rawData)
          .sum((d) => d.size || 0);
        setData(newData);
      }
      // } else if (renderDepth === 3 && rawData?.length > 0) {
      //   const matching = rawData.filter((d) => (d.id === currentTreeNode?.data?.id) || (d.parent === currentTreeNode?.data?.id) || (d.id === "Manufacturers") || (d.id === "Inventory"));
      //   const newData = stratify()
      //     .id((d) => d.id)
      //     .parentId((d) => d.parent)(matching)
      //     .sum((d) => d.size || 0);
      //   setData(newData);
      // }
    }, [currentTreeNode?.data?.id, rawData, renderDepth]);

  // Define some constants for colors
  const background = '#fff';

  const max = Math.max(...rawData.map((d) => d.size || 0));
  // Create the color scale
  const colorScale = scaleLinear({
    domain: [0, max],
    range: ['#b9bfd6', '#372d5b'],
  });

  // If width is too small, return null
  if (width < 10) return null;

  const handleTileMethodChange = (node) => {
    if (renderDepth === 2) {
      const matching = rawData.filter((d) => (d.id === node.data.id) || (d.parent === node.data.id) || (d.id === "Manufacturers") || (d.id === "Inventory"));
      const newData = stratify()
        .id((d) => d.id)
        .parentId((d) => d.parent)(matching)
        .sum((d) => d.size || 0);
      setData(newData);

      setRenderDepth(3);
    }
    handleTileClicked(node);
  };

  const handleTileHover = (node) => {
    setSelectedNodeId(node.data.id);
    setHoveredTreeNode(node);
  };

  const handleTileLeave = (node) => {
    if (selectedNodeId === node.data.id) {
      setSelectedNodeId(false);
      setHoveredTreeNode(null);
    }
  };
  return (
    <div className="relative">
      <div>
        <svg width={width} height={height}>
          <PatternLines
            id="lines"
            height={5}
            width={5}
            stroke={'#E6E8F0'}
            strokeWidth={1}
            orientation={['diagonal']}
          />
          <PatternLines
            id="waves"
            height={5}
            width={5}
            stroke={'#b9bfd660'}
            strokeWidth={1}
            orientation={['diagonal']}
          />
          <rect width={width} height={height} rx={14} fill="url('#lines')" />
          {root && <Treemap
            top={margin.top}
            root={root}
            size={[xMax, yMax]}
            tile={tileMethods[treemapSquarify]}
            round
          >
            {(treemap) => (
              <Group>
                {treemap
                  .descendants()
                  .reverse()
                  .map((node, i) => {
                    const nodeWidth = node.x1 - node.x0;
                    const nodeHeight = node.y1 - node.y0;
                    const isSelected = selectedNodeId === node.data.id;

                    const borderRadius = (nodeWidth || nodeHeight) < 12 ? 1 : (nodeWidth || nodeHeight) < 24 ? 2 : (nodeWidth || nodeHeight) < 48 ? 4 : 6;
                    
                    return (
                      <Group
                        key={`node-${i}`}
                        top={node.y0 + margin.top}
                        left={node.x0 + margin.left}
                        style={{ cursor: node.depth > 2 ? 'pointer' : 'default' }}
                      >
                        {renderDepth === node.depth && (
                          <g onClick={() => handleTileMethodChange(node)} onMouseEnter={() => handleTileHover(node)} onMouseLeave={() => handleTileLeave(node)}>
                            <rect
                              width={nodeWidth}
                              height={nodeHeight}
                              stroke={background}
                              strokeWidth={2}
                              rx={borderRadius}
                              ry={borderRadius}
                              fill={isSelected ? "#FFCC16" : colorScale(node.value || 0)}
                            />
                            <rect
                              width={nodeWidth}
                              height={nodeHeight}
                              rx={borderRadius}
                              ry={borderRadius}
                              fill="url('#waves')" />
                            {((node.data.data.label.length * 6 + 28) < nodeWidth) && (36 < nodeHeight) && (
                              <>
                                <rect
                                  x={8}
                                  y={8}
                                  width={node.data.data.label.length * 6 + 12}
                                  height={20}
                                  rx={5} // border radius
                                  ry={5} // border radius
                                  fill="#fff" // transparent black fill
                                  stroke="#372d5b"
                                />
                                <text
                                  x={14}
                                  y={21}
                                  fill="#000"
                                  fontSize={10}
                                  fontWeight={700}
                                  fontFamily="SF Mono"
                                >
                                  {node.data.data.label}
                                </text>
                              </>
                            )}
                          </g>
                        )}
                      </Group>
                    );
                  })}
              </Group>
            )}
          </Treemap>}
        </svg>
      </div>
      <SimpleLoader loading={loading} />
    </div>
  );
};

export default React.memo(TreeChart);