import Button from './Button';
import './style/TextAreaModal.css';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setShowModal } from '../../redux/keystoneInterfaceSlice';
import Label from './Label';
import TextArea from './TextArea';

const TextAreaModal = ({ title, cta, ctaLoading, inputLabel, inputPlaceholder, onSubmit }) => {
    const dispatch = useDispatch();
    const inputRef = useRef(null);

    const handleSendFeedback = () => {
        const inputValue = inputRef.current.value;
        console.log(inputValue);
        onSubmit(inputValue);
    };

    return (
        <div className="keystone-text-area-modal modal-wrapper">
            <div className="modal-header flex-1 pad-m pad-h-l space-between align-center">
                <Label content={title} size="m" bold="true" displayFont={true} />
                <Button label="Close" onClick={() => dispatch(setShowModal(false))} />
            </div>
            <div className="pad-l no-pad-bot">
                <div className="product-horizontal-container mobile-vertical h-gap-m v-gap-m flex-1 justify-end">
                    <div className="product-vertical-container v-gap-xs flex-1">
                        <Label content={inputLabel} size="s" bold="true" displayFont={true} />
                        <TextArea placeholder={inputPlaceholder} type="text" inputRef={inputRef} large={true} onWhite={false} />
                    </div>
                </div>
            </div>
            <div className="modal-footer flex-1 justify-end pad-l h-gap-l">
                <div className="product-vertical-container">
                    <div className="product-horizontal-container h-gap-s">
                        <Button brand={true} label={cta} loading={ctaLoading} large="true" onClick={handleSendFeedback} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(TextAreaModal);